import { JetView } from "webix-jet";
import { sort } from "../helpers/common";

export default class TreeView extends JetView {
	config() {
		const state = (this.State = this.getParam("state", true));
		this.Local = this.app.getService("local");
		this.Helpers = this.app.getService("helpers");
		const scales = this.Local.getScales();
		const _ = (this._ = this.app.getService("locale")._);
		const compact = (this.Compact = this.getParam("compact", true));
		this.isResources = this.app.config.resources;
		this.maxAvatarNumber = 3;

		const action = {
			id: "action",
			css: "webix_gantt_action",
			header: {
				text: `<span webix_tooltip="${_(
					"Add task"
				)}" class="webix_icon wxi-plus-circle"></span>`,
				css: "webix_gantt_action",
			},
			template: () => {
				return `<span webix_tooltip="${_(
					"Add task"
				)}" class="webix_icon wxi-plus"></span>`;
			},
			width: 44,
			minWidth: 44,
		};

		let columns = [
			{
				id: "text",
				css: "webix_gantt_title",
				header: _("Title"),
				template: (obj, common) =>
					common.treetable(obj, common) + this.TitleTemplate(obj),
				fillspace: true,
				hidden: compact,
				batch: "full",
				sort: sort("text"),
				minWidth: 150,
			},
			{
				id: "start_date",
				header: _("Start date"),
				format: webix.i18n.dateFormatStr,
				hidden: compact,
				batch: "full",
				sort: "date",
				minWidth: 100,
			},
		];

		if (this.isResources) {
			columns.push({
				id: "resources",
				css: "webix_gantt_tree_column_resources",
				header: _("Assigned"),
				template: obj => this.ResourcesTemplate(obj),
				minWidth: 100,
			});
		}

		if (compact) {
			action.header.text = "<span class='webix_icon gti-menu'>";
			if (state.readonly) action.template = "";
			columns.unshift(action);
		} else if (!state.readonly) columns.push(action);

		columns[columns.length - 1].resize = false;

		const skin = webix.skin.$name;
		const headerCss =
			skin == "material" || skin == "mini" ? "webix_header_border" : "";
		const tree = {
			view: "treetable",
			css: "webix_gantt_tree " + headerCss,
			prerender: true,
			width: compact ? 44 : state.treeWidth,
			rowHeight: scales.cellHeight,
			headerRowHeight: scales.height,
			scroll: "xy",
			scrollAlignY: false,
			select: "row",
			sort: "multi",
			resizeColumn: {
				headerOnly: true,
				size: 10,
			},
			drag: state.readonly ? false : "order",
			columns,
			tooltip: () => "",
			onClick: {
				"wxi-plus": (ev, id) => this.AddTask(id.row),
				"wxi-plus-circle": () => this.AddTask("0"),
				"gti-menu": () => this.ToggleColumns(),
			},
			on: {
				onBeforeOpen: id => this.HandleToggle(id),
				onAfterOpen: id => {
					this.ToggleBranch(id, 1);
					this.ApplySelection();
				},
				onAfterClose: id => this.ToggleBranch(id, 0),
				onBeforeSelect: id => this.BeforeSelectHandler(id),
				onItemClick: id => this.ItemClickHandler(id),
				onAfterSort: (by, dir, as) => this.SortTasks(by, dir, as),
				onColumnResize: (id, v, o, user) => this.NormalizeColumns(id, user),
				onBeforeDrag: (ctx, e) => this.BeforeDragHandler(ctx, e),
				onBeforeDrop: ctx => this.BeforeDropHandler(ctx),
				onViewResize: () => {
					this.State.treeWidth = this.Tree.$width;
				},
			},
		};

		tree.on["onScrollY"] = tree.on["onSyncScroll"] = tree.on[
			"onAfterScroll"
		] = function() {
			state.top = this.getScrollState().y;
		};
		return tree;
	}

	init(view) {
		this.Tree = view;
		this.State = this.getParam("state", true);
		this.Ops = this.app.getService("operations");
		this.Helpers = this.app.getService("helpers");
		this.Tasks = this.Local.tasks();

		this.on(this.State.$changes, "display", v => {
			this.Mode = v;
			this.State.top = 0;
			this.SyncData();
		});
		if (this.isResources) {
			this.on(this.Assignments.data, "onStoreUpdated", () => {
				this.Tree.refresh();
			});
			this.on(this.Tree.data, "onStoreUpdated", id => {
				if (this.Mode == "resources" && !id) {
					this.ApplySelection();
				}
			});
		}

		this.on(this.State.$changes, "top", y => this.Tree.scrollTo(null, y));
		this.on(this.State.$changes, "selected", id => {
			if (id) this.ApplySelection();
			else this.Tree.unselect();
		});

		// resetting headerRowHeight
		this.on(this.app, "onScalesUpdate", scales => {
			const col = this.Tree.columnId(0);
			this.Tree.getColumnConfig(col).header[0].height = scales.height;
			this.Tree.refreshColumns();
		});

		// compact button and dnd module
		this.on(this.app, "task:add", (pid, dates) =>
			this.AddTask(pid || "0", dates)
		);

		this.on(this.State.$changes, "treeWidth", v => {
			if (!this.Compact && view.$width !== v) {
				view.define({ width: v });
				view.resize();
			}
		});
	}

	/**
	 * Prevents split task branches from being opened
	 * @param {(string|number)} id - the ID of a task branch
	 * @returns {Boolean} return false to prevent branches from opening
	 */
	HandleToggle(id) {
		const obj = this.Tree.getItem(id);
		if (obj.type === "split" && obj.$data.length) return false;
	}

	/**
	 * Selects a task
	 * @param {(string|number)} id - the ID of a task
	 */
	ItemClickHandler(id) {
		if (!this.Tree.getItem(id).$group) {
			this.State.$batch({
				parent: null,
				selected: id.row,
			});
		} else this.app.callEvent("edit:stop");
	}
	/**
	 * Checks whether selection is allowed
	 * @param {string, number} id - task ID
	 * @returns {boolean} returning false prevents selection
	 */
	BeforeSelectHandler(id) {
		return !this.Tree.getItem(id).$group;
	}
	/**
	 * Checks whether drag-n-drop is allowed and defines the looks of the drag marker
	 * @param {Object} ctx - the context object of DnD
	 * @param {MouseEvent} e - native browser event
	 * @returns {Boolean} returning false prevents Webix drag-n-drop logic
	 */
	BeforeDragHandler(ctx, e) {
		if (this.Mode == "tasks") {
			const obj = this.Tree.getItem(ctx.start);
			if (obj.type === "split" && obj.$data.length) {
				const html = this.Tree.$dragHTML(this.Tree.getItem(ctx.start), e, ctx);
				ctx.html = html.replace(
					"webix_dd_drag",
					"webix_dd_drag webix_gantt_tree_no_icon"
				);
			}

			return true;
		}
		return false;
	}
	/**
	 * Saves task position
	 * @param {Object} ctx - drag-n-drop context
	 * @returns {boolean} returning false prevents Webix drop logic
	 */
	BeforeDropHandler(ctx) {
		this.Ops.moveTask(ctx.start, ctx.parent, ctx.index);
		return false;
	}
	/** Fills tree width data */
	SyncData() {
		this.Tree.clearAll();
		this.VisibleTasks = this.Local.getVisibleTasksCollection();
		this.resDisplayed = this.State.display === "resources";

		// for Resource view: always handle existing waitData of VisibleTasks
		if (this.resDisplayed && !this.clearEvID) {
			this.clearEvID = this.on(this.VisibleTasks.data, "onClearAll", () => {
				if (this.resDisplayed) {
					this.RefreshData();
				}
			});
		}

		this.RefreshData();
	}

	/**
	 * Returns waitData of all involved Collections
	 * @returns {Array} of Promises
	 */
	GetLoaders() {
		const loaders = [this.VisibleTasks.waitData];
		if (this.isResources) {
			this.Resources = this.Local.resources();
			this.Assignments = this.Local.assignments();
			loaders.push(this.Resources.waitData, this.Assignments.waitData);
		}
		return loaders;
	}

	/**
	 * Waits for data before syncing with main collection and restoring state
	 * @param {Array} loaders -
	 */
	RefreshData() {
		const loaders = this.GetLoaders();
		webix.promise.all(loaders).then(() => {
			this.Tree.sync(this.VisibleTasks);
			this.ApplySelection();
			this.ApplySorting();
		});
	}

	/** Applies selection from state */
	ApplySelection() {
		const id = this.State.selected;

		if (id && this.Tree.exists(id) && this.Tree.getSelectedId() != id) {
			let selected;
			const task = this.Tree.getItem(id);

			// split subtasks are in the tree but they are never visible
			if (this.State.display === "tasks") {
				const parent = task.parent;
				const pdata = parent != 0 ? this.Tree.getItem(parent) : null;
				if (pdata && pdata.type === "split") {
					selected = parent;
				} else if (this.Local._isTaskVisible(task)) {
					selected = id;
				}
			} else if (this.Local._isTaskVisible(task)) {
				selected = id;
			}

			if (selected) {
				this.Tree.select(selected);
				this.Tree.showItem(selected);
			}
		}
	}
	/** Applies sorting from state */
	ApplySorting() {
		if (this.State.sort) {
			// UI-only: no need to re-apply sorting on collection
			this.Tree.data.blockEvent();
			this.Tree.setState({ sort: this.State.sort });
			this.Tree.data.unblockEvent();
		}
	}

	/**
	 * Adds a new task
	 * @param {string, number} pid - ID of parent task, can be "0" for root
	 * @param {Object} dates - start and end of the new task in units (numbers)
	 * @returns {boolean} returning false prevents selection of the parent task, if called from tree
	 */
	AddTask(pid, dates) {
		this.State.selected = null;

		const row = this.Tree.getItem(pid);
		const index = row ? 0 : -1;
		let inProgress;
		const newTask = this.GetNewTask(row, dates);

		if (this.Mode == "resources" && row) {
			inProgress = this.AddTaskWithAssignment(newTask, pid);
		} else {
			// row and dates come from drag-n-drop module
			if (row && dates) {
				let assigned;
				if (this.app.config.resources) {
					assigned = this.Assignments.find(a => a.task == row.id);
				}
				if (assigned && assigned.length)
					inProgress = this.SplitTaskWithAssignment(
						newTask,
						index,
						pid,
						row,
						assigned
					);
				else inProgress = this.Ops.splitTaskWithDnd(newTask, index, pid, row);
			} else inProgress = this.Ops.addTask(newTask, index, row ? pid : 0);
		}
		this.app.callEvent("backend:operation", [inProgress]);

		inProgress.then(res => {
			if (row && this.Mode == "tasks" && row.type !== "split") {
				this.Tree.open(pid);
			}
			this.State.$batch({
				edit: true,
				selected: res.id,
			});

			this.Tree.showItem(res.id);
		});

		return false;
	}
	/**
	 * Returns and object with new task data
	 * @param {Object} row - the data object of parent task
	 * @param {Object} dates - (optional) start and length of the new task in units (numbers)
	 * @returns {Object} an object with default task fields
	 */
	GetNewTask(row, dates) {
		const { start, end } = this.GetDates(row, dates);

		return {
			start_date: new Date(start),
			end_date: end || webix.Date.add(start, 1, "day", true),
			progress: 0,
			text: "",
		};
	}

	/**
	 * Gets start and end dates for a new task
	 * @param {Object} row - (optional) the data object of a parent task
	 * @param {Object} dates - (optional) start and length of the new task in units (numbers)
	 * @returns {Object} with start and end dates
	 */
	GetDates(row, dates) {
		if (dates) {
			return dates;
		}

		let start, end;
		if (row && row.type === "split") {
			const date =
				row.$data && row.$data.length ? row.$data[0].end_date : row.end_date;
			start = this.Helpers.addUnit("day", date, 1);
		} else {
			if (row) {
				start = row.start_date;
			} else {
				const scales = this.Local.getScales();
				start = webix.Date.add(scales.start, 1, scales.minUnit); //keep scale start
			}
		}
		return { start, end };
	}

	/**
	 * Adds a new task with assignment, if any is applicable
	 * @param {Object} newTask - the data object of a new task
	 * @param {(string|number)} pid - the ID of the parent task
	 * @returns {Promise} server response with ID of the new task
	 */
	AddTaskWithAssignment(newTask, pid) {
		this.Tasks.data.blockEvent();
		this.Assignments.data.blockEvent();
		// add task
		let targetId = "0";
		if (pid) targetId = this.Tree.getItem(pid).$group ? "0" : pid;
		let index = 0;
		if (this.Tasks.data.branch[targetId])
			index = this.Tasks.data.branch[targetId].length;

		return this.Ops.addTask(newTask, index, targetId)
			.then(obj => {
				newTask.id = obj.id;
				// add assignments
				let waitArr = [];
				let resources = null;
				while (pid != "0" && !resources) {
					resources = this.Tree.getItem(pid).resources;
					if (!resources) pid = this.Tree.getParentId(pid);
				}
				if (resources && resources.length) {
					resources.forEach(rId => {
						const resource = this.Local.resources().getItem(rId);
						waitArr.push(
							this.Ops.addAssignment({
								resource: rId,
								value: this.Helpers.getDefaultResourceValue(resource),
								task: newTask.id,
							})
						);
					});
				}
				if (!waitArr.length) return webix.promise.resolve(obj);
				return webix.promise.all(waitArr).then(() => obj);
			})
			.finally(() => {
				this.Tasks.data.unblockEvent();
				this.Assignments.data.unblockEvent();
				//now we can refresh
				this.Tasks.data.callEvent("onStoreUpdated", [
					newTask.id,
					newTask,
					"add",
				]);
			});
	}

	/**
	 * Splits a task and reassigns resources from original task to new kid if the original task was not split
	 * @param {Object} newTask - data for a new split kid
	 * @param {number} index - the index of the new kid
	 * @param {(number|string)} pid - the ID of the original task
	 * @param {Object} row - data of the original task
	 * @param {Object} assignment - data of the assignment of the original task
	 * @returns {Promise<Object>} server response with ID of the new task, it's 'sibling' ID
	 */
	SplitTaskWithAssignment(newTask, index, pid, row, assignments) {
		return this.Ops.splitTaskWithDnd(newTask, index, pid, row).then(res => {
			if (res.sibling) {
				const aops = [];
				for (let i = 0; i < assignments.length; ++i)
					aops.push(
						this.Ops.updateAssignment(assignments[i].id, {
							task: res.sibling,
						}).then(() => res)
					);
				return webix.promise.all(aops).then(() => res);
			}
			return res;
		});
	}

	/** Invokes tasks painting in the chart */
	RefreshTasks() {
		this.Tasks.data.callEvent("onStoreUpdated", []);
	}
	/**
	 * Sorts tasks according to current sorting config
	 * @param {string, array} by - data field, can store an array of sorting configs in case of multiple sorting
	 * @param {string, number} dir - sorting direction, "asc" or "desc"
	 * @param {string, number} as - data type, "string" by default
	 */
	SortTasks(by, dir, as) {
		const dataSorter = webix.isArray(by) ? by : { by, dir, as };
		const sortState = this.Tree.getState().sort;

		// sort the collection to affect task bars
		this.VisibleTasks.sort(dataSorter);
		// save sorting state to restore it after app.refresh (switching from/to compact mode)
		this.State.sort = sortState;
	}
	/** Shows and hides columns in a compact mode */
	ToggleColumns() {
		const vis = this.Tree.isColumnVisible("text");
		this.Tree.showColumnBatch("full", !vis);
		this.Tree.config.width = vis ? 44 : 0;
		this.Tree.resize();

		this.app.callEvent("bars:toggle", [vis]);
	}
	/**
	 * Adjusts columns after resizing
	 * @param {string} id - ID of the column being resized
	 * @param {boolean} user - defines whether resizing was triggered by user or API
	 */
	NormalizeColumns(id, user) {
		if (!user) return;
		const tree = this.Tree;
		const columns = tree.config.columns;
		const i = tree.getColumnIndex(id);

		let last = columns.length - 1;
		if (columns[last].id === "action") last -= 1;

		if (i === 0) {
			columns[last].fillspace = true;
			columns[last].width = 0;
		} else if (i === last) {
			columns[0].fillspace = true;
			columns[0].width = 0;
		}
		tree.refreshColumns();
	}
	/**
	 * Saves a task tree branch state (open or closed)
	 * @param {number, string} id - the ID of the task (branch node)
	 * @param {number} open - 1 if open, 0 if closed
	 */
	ToggleBranch(id, open) {
		if (this.State.display != "resources") {
			this.RefreshTasks();
			if (!this.State.readonly) this.Ops.updateTask(id, { opened: open });
		} else {
			const tree = this.VisibleTasks;
			const item = tree.getItem(id);
			item.open = !!open;
			// need to update treestore order
			tree.data.callEvent("onStoreUpdated", [id, 0, "branch"]);
		}
	}

	/**
	 * Returns a string with the text label for item title
	 * @param {Object} obj - the data object of a task
	 * @returns {string} a string with the text label
	 */
	TitleTemplate(obj) {
		return (
			obj.text || (obj.$group ? this._("Unassigned") : this._("(no title)"))
		);
	}

	/**
	 * Resources cell template
	 * @param {object} obj - task data item
	 * @returns {string} html string
	 */
	ResourcesTemplate(obj) {
		if (this.Assignments.count() && this.Resources.count()) {
			let assigned = [];
			this.Assignments.data.each(a => {
				if (a.task == obj.id) assigned.push(this.Resources.getItem(a.resource));
			});
			assigned.sort(this.Ops.sortResources);
			return this.GetAssignmentsHTML(assigned);
		}
		return "";
	}

	/**
	 * Gets html content for assignments cell
	 * @param {Array} assignedArr - an array of assignments
	 * @returns {string} html string
	 */
	GetAssignmentsHTML(assignedArr) {
		let str = "";
		if (assignedArr.length > this.maxAvatarNumber) {
			const num = assignedArr.length - this.maxAvatarNumber + 1;
			assignedArr = assignedArr.splice(0, this.maxAvatarNumber);
			// show remaining assignments as "+[NUM]" over the last avatar
			const last = assignedArr.pop();
			const lastStr = this.Helpers.resourceAvatar(last);
			const numStr =
				"<div class='webix_gantt_cell_remain_num'>+" + num + "</div>";
			str = assignedArr
				.map(r => `${this.Helpers.resourceAvatar(r, null, true)}`)
				.join("");
			str +=
				"<div class='webix_gantt_cell_assigned_last'>" +
				lastStr +
				numStr +
				"</div>";
		} else {
			str = assignedArr
				.map(r => `${this.Helpers.resourceAvatar(r, null, true)}`)
				.join("");
			// show a name for single assignment
			if (assignedArr.length == 1) {
				let arr = assignedArr[0].name.split(" ");
				str +=
					"<span class='webix_gantt_cell_assigned_text'>" + arr[0] + "</span>";
			}
		}
		return "<div class='webix_gantt_avatar_box'>" + str + "</div>";
	}
}
