import { JetView } from "webix-jet";
import TreeView from "./tree";
import ChartView from "./chart";
import { initRLayout } from "@xbs/jet-helpers";

export default class TopView extends JetView {
	config() {
		initRLayout();
		this.fCompact = this.getParam("forceCompact");
		if (!webix.isUndefined(this.fCompact))
			this.setParam("compact", this.fCompact);
		this.Compact = this.getParam("compact");
		this.State = this.getParam("state", true);

		const edit = this.Compact
			? { $subview: true, name: "edit", popup: true }
			: {
					view: "proxy",
					css: "webix_shadow_medium",
					borderless: true,
					width: 400,
					hidden: true,
					localId: "edit",
					body: { $subview: true, name: "edit" },
			  };

		let cols = [
			TreeView,
			{
				view: this.Compact ? "spacer" : "resizer",
				css: "webix_gantt_resizer",
				width: this.Compact ? 1 : 4,
			},
			ChartView,
		];

		if (this.app.config.resources) {
			cols = [
				{
					type: "clean",
					rows: [
						{
							type: "clean",
							cols,
							on: {
								onViewResize: () => {
									this.app.callEvent("rdiagram:resize", []);
								},
							},
						},
						{
							view: "resizer",
							css: "webix_gantt_resizer",
							localId: "rdResizer",
						},
						{
							view: "proxy",
							localId: "rdiagram",
							height: this.Compact ? 0 : 400,
							body: { $subview: true, name: "rdiagram" },
						},
					],
				},
				edit,
			];
		} else {
			cols.push(edit);
		}

		const main = {
			view: "abslayout",
			css: "webix_gantt",
			cells: [
				{
					view: "r-layout",
					localId: "main",
					borderless: true,
					relative: true,
					type: "clean",
					cols: cols,
				},
				{
					css: "webix_gantt_absbutton",
					view: "icon",
					icon: "wxi-plus",
					localId: "add",
					hidden: !this.Compact || this.State.readonly,
					width: 50,
					height: 50,
					right: 20,
					bottom: 20,
					click: () => {
						this.app.callEvent("task:add", []);
					},
				},
			],
		};

		return main;
	}

	init(view) {
		this.$$("main").sizeTrigger(
			this.app,
			mode => this.SetCompactMode(mode),
			!!this.Compact
		);

		this.on(this.State.$changes, "selected", (id, oid) => {
			if (id) this.ShowTask(this.State.edit ? "form" : "info");
			else if (oid) this.HideTask();
		});
		this.on(this.State.$changes, "edit", v => {
			if (this.State.selected) this.ShowTask(v ? "form" : "info");
		});

		webix.extend(view, webix.ProgressBar);
		this.on(this.app, "backend:operation", res => {
			view.showProgress({ type: "top", delay: 2000 });
			res.finally(() => {
				view.hideProgress();
			});
		});

		if (this.app.config.resources) {
			this.on(this.State.$changes, "resourcesDiagram", v => {
				if (v) {
					this.$$("rdiagram").show();
					this.$$("rdResizer").show();
					this.show("rdiagram", { target: "rdiagram" });
				} else {
					this.$$("rdiagram").hide();
					this.$$("rdResizer").hide();
					this.show("_blank", { target: "rdiagram" }).then(() => {
						this.app.callEvent("rdiagram:resize", []);
					});
				}
			});
		}
	}

	ShowTask(path) {
		path = `task.${path}`;
		if (this.Compact) {
			this.show(`task.popup/${path}`, {
				target: "edit",
			});
		} else {
			this.$$("edit").show();
			this.show(path, {
				target: "edit",
			});
		}
	}

	HideTask() {
		// we need to destroy the view inside first
		this.show("_blank", { target: "edit" });
		if (!this.Compact) this.$$("edit").hide();
	}

	SetCompactMode(mode) {
		//let it render, then recreate
		webix.delay(() => {
			this.State.$batch({ top: 0, left: 0 });
			this.setParam("compact", mode);
			this.refresh();
		});
	}
}
