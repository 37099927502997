import { JetApp, plugins, EmptyRouter } from "webix-jet";
import { initJetWin } from "@xbs/jet-helpers";
import { createState, link } from "jet-restate";

import views from "./export_views";
import en from "locales/en";

// services
import Local from "./models/Local";
import Backend from "./models/Backend";
import Operations from "./models/Operations";
import Helpers from "./models/Helpers";
import Grouping from "models/Grouping";

export class App extends JetApp {
	constructor(config) {
		// widget state
		const state = createState({
			top: 0,
			left: 0,
			selected: null,
			edit: null,
			readonly: config.readonly || false,
			sort: null,
			display:
				config.resources && config.display == "resources"
					? "resources"
					: "tasks",
			criticalPath: config.criticalPath || false,
			resourcesDiagram: !!(config.resources && config.resourcesDiagram),
			treeWidth: config.treeWidth || (config.resources ? 400 : 500),
			baseline: config.baseline || false,
		});

		const isHoliday =
			config.isHoliday ||
			function(date) {
				const d = date.getDay();
				return d === 0 || d === 6;
			};

		const defaults = {
			router: EmptyRouter,
			version: VERSION,
			debug: DEBUG,
			start: "/top",
			compactWidth: 650,
			markers: [{ css: "webix_gantt_today_marker", now: true }],
			params: { state, forceCompact: config.compact },
			links: true,
			isHoliday,
		};

		super({ ...defaults, ...config });

		const dynamic = obj =>
			this.config.override ? this.config.override.get(obj) || obj : obj;

		//order matters
		this.setService("helpers", new (dynamic(Helpers))(this, config));
		this.setService("local", new (dynamic(Local))(this, config));
		this.setService("backend", new (dynamic(Backend))(this, config.url));
		this.setService("operations", new (dynamic(Operations))(this));
		this.setService("grouping", new (dynamic(Grouping))(this));
		initJetWin(this);

		this.use(
			plugins.Locale,
			this.config.locale || {
				lang: "en",
				webix: {
					en: "en-US",
				},
			}
		);
	}

	require(type, name) {
		if (type === "jet-views") return views[name];
		else if (type === "jet-locales") return locales[name];

		return null;
	}

	// for external users
	getState() {
		return this.config.params.state;
	}
}

webix.protoUI(
	{
		name: "gantt",
		app: App,
		getState() {
			return this.$app.getState();
		},
		getService(name) {
			return this.$app.getService(name);
		},
		$init() {
			const state = this.$app.getState();
			for (let key in state) {
				link(state, this.config, key);
			}

			this.$app.attachEvent("bars:beforedrag", (item, ctx) =>
				this.callEvent("onBeforeDrag", [item, ctx])
			);
			this.$app.attachEvent("bars:beforedrop", (item, ctx) =>
				this.callEvent("onBeforeDrop", [item, ctx])
			);
		},
		$exportView: function(options) {
			if (
				options.export_mode === "png" ||
				(options.export_mode === "pdf" && options.display === "image")
			)
				return this.$app.getRoot();
			return this.$app.getService("local").tasks();
		},
		clearAll() {
			this.$app.getService("local").clearAll();
		},
		reload() {
			this.$app.getService("local").reload();
		},
	},
	webix.ui.jetapp
);

// re-export for customization
const services = { Local, Backend, Operations, Helpers, Grouping };
const locales = { en };
export { views, locales, services };
