import { JetView } from "webix-jet";

export default class TableView extends JetView {
	config() {
		const _ = this.app.getService("locale")._;

		const table = {
			view: "treetable",
			css: "webix_gantt_tree webix_gantt_form_tree",
			header: false,
			borderless: true,
			localId: "links",
			autoheight: true,
			editable: true,
			scroll: false,
			hover: "webix_gantt_link_table_hover",
			scheme: {
				$group: {
					by: "ttype",
				},
			},
			columns: [
				{
					id: "task",
					css: "webix_gantt_title",
					header: _("Task"),
					fillspace: true,
					template: function(obj) {
						if (obj.$group)
							return `<span class="webix_strong">${_(
								obj.ttype == "source" ? _("Successors") : _("Predecessors")
							)}</span>`;
						else return obj.text || _("(no title)");
					},
				},
				{
					id: "type",
					css: "webix_gantt_type",
					header: _("Link"),
					width: 140,
					template: function(obj, c, v, conf) {
						if (obj.$group) return "";
						else {
							const type = conf.collection.getItem(v).value;
							return `${type}<span class="webix_icon wxi-menu-down"></span>`;
						}
					},
					editor: "richselect",
					options: [
						{ id: "0", value: _("End to start") },
						{ id: "1", value: _("Start to start") },
						{ id: "2", value: _("End to end") },
						{ id: "3", value: _("Start to end") },
					],
				},
				{
					width: 30,
					css: "webix_gantt_action",
					template: function(obj) {
						return obj.$group
							? ""
							: "<span class='webix_icon wxi-trash'></span>";
					},
				},
			],
			on: {
				onBeforeEditStart: function(id) {
					return !this.getItem(id).$group;
				},
				onBeforeEditStop: (v, editor) => {
					if (v.value != v.old) {
						this.UpdateLink(editor.row, { type: v.value * 1 });
						return false;
					}
				},
				onAfterLoad: function() {
					this.openAll();
				},
			},
			onClick: {
				"wxi-trash": (e, id) => {
					webix
						.confirm({
							container: this.app.getRoot().$view,
							title: _("Delete link"),
							text: _("The link will be deleted permanently, are you sure?"),
						})
						.then(() => this.DeleteLink(id));
				},
			},
		};

		return {
			padding: { top: webix.skin.$active.layoutMargin.form, bottom: 5 },
			rows: [table],
		};
	}

	init() {
		this.State = this.getParam("state", true);
		this.Ops = this.app.getService("operations");
		this.Links = this.$$("links");

		this.on(this.State.$changes, "selected", id => {
			if (id) this.FillData(id);
		});

		// changing links via bars and dnd
		const links = this.app.getService("local").links();
		this.on(links.data, "onStoreUpdated", (_id, obj) => {
			if (obj) {
				const tid = this.State.selected;
				if (obj.target === tid || obj.source === tid) {
					this.FillData(tid);
				}
			}
		});
	}

	FillData(id) {
		const local = this.app.getService("local");
		const related = local
			.getLinks(id, "target")
			.concat(local.getLinks(id, "source"));

		this.Links.clearAll();
		if (related.length) {
			this.Links.parse(related);
			this.getRoot().show();
		} else {
			this.getRoot().hide();
		}
	}

	UpdateLink(id, obj) {
		this.Ops.updateLink(id, obj).then(() => {
			this.Links.blockEvent();
			this.Links.editCancel();
			this.Links.unblockEvent();

			// must not give 0 ID to dropdown list of column editor
			obj.type += "";
			this.Links.updateItem(id, obj);
		});
	}

	DeleteLink(id) {
		this.Ops.removeLink(id).then(() => {
			this.FillData(this.State.selected);
		});
	}
}
