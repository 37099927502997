import m0 from "./views/chart/bars";
import m1 from "./views/chart/index.js";
import m2 from "./views/chart/scale";
import m3 from "./views/rdiagram/diagram";
import m4 from "./views/rdiagram/index.js";
import m5 from "./views/rdiagram/tree";
import m6 from "./views/task/baseline";
import m7 from "./views/task/form";
import m8 from "./views/task/info";
import m9 from "./views/task/links";
import m10 from "./views/task/popup";
import m11 from "./views/task/resources";
import m12 from "./views/top";
import m13 from "./views/tree";
import { JetView } from "webix-jet";

const views = { JetView };
views["chart/bars"] = m0;
views["chart"] = m1;
views["chart/scale"] = m2;
views["rdiagram/diagram"] = m3;
views["rdiagram"] = m4;
views["rdiagram/tree"] = m5;
views["task/baseline"] = m6;
views["task/form"] = m7;
views["task/info"] = m8;
views["task/links"] = m9;
views["task/popup"] = m10;
views["task/resources"] = m11;
views["top"] = m12;
views["tree"] = m13;

export default views;
