export default {
	Edit: "Edit",
	Done: "Done",
	"Delete task": "Delete task",
	"Delete link": "Delete link",
	"The task will be deleted permanently, are you sure?":
		"The task will be deleted permanently, are you sure?",
	"The link will be deleted permanently, are you sure?":
		"The link will be deleted permanently, are you sure?",
	Predecessors: "Predecessors",
	Successors: "Successors",

	Title: "Title",
	"Start date": "Start date",
	"End date": "End date",
	Duration: "Duration",
	Progress: "Progress",
	Notes: "Notes",
	Type: "Type",
	Project: "Project",
	Milestone: "Milestone",
	"Add task": "Add task",
	"Add project": "Add project",
	"(no title)": "(no title)",
	"Save changes?": "Save changes?",

	"Related tasks": "Related tasks",
	Task: "Task",
	Link: "Link",
	"End to start": "End to start",
	"Start to start": "Start to start",
	"End to end": "End to end",
	"Start to end": "Start to end",

	Lasts: "Lasts",
	day: "day",
	days: "days",

	"Add assignment": "Add assignment",
	Assignment: "Assignment",
	Assignments: "Assignments",
	Assigned: "Assigned",
	"Delete assignment": "Delete assignment",
	"Are you sure to delete this assignment?":
		"Are you sure to delete this assignment?",
	"No resources to add": "No resources to add",
	Unassigned: "Unassigned",

	hour: "h",
	"Hours per": "Hours per",
	"Tasks per": "Tasks per",
	Name: "Name",
	Hours: "Hours",
	Tasks: "Tasks",
	month: "month",
	week: "week",
	quarter: "quarter",
	year: "year",

	"Split task": "Split task",
	"Planned dates": "Planned dates",
	"Planned dates will be removed, are you sure?":
		"Planned dates will be removed, are you sure?",
	"Add dates": "Add dates",
	"Remove dates": "Remove dates",
};
