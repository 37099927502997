import { JetView } from "webix-jet";

export default class BaselineView extends JetView {
	config() {
		const _ = (this._ = this.app.getService("locale")._);
		const calendar = {
			css: "webix_gantt_datepicker",
			type: "calendar",
			body: {
				height: 270,
				width: 250,
				icons: true,
				events: date => this.app.config.isHoliday(date) && "webix_cal_event",
			},
		};

		const form = {
			view: "form",
			localId: "form",
			borderless: true,
			autoheight: true,
			elementsConfig: {
				labelPosition: "top",
			},
			padding: {
				left: 3,
				right: 3,
			},
			visibleBatch: "added",
			elements: [
				{
					batch: "added",
					rows: [
						{
							view: "datepicker",
							name: "planned_start",
							label: _("Start date"),
							suggest: webix.copy(calendar),
						},
						{
							view: "datepicker",
							name: "planned_end",
							label: _("End date"),
							suggest: webix.copy(calendar),
						},
						{
							view: "counter",
							name: "planned_duration",
							css: "webix_gantt_form_counter",
							min: 1,
							max: 1000,
							label: _("Duration"),
						},
					],
				},
				{
					batch: "none",
					cols: [
						{
							view: "button",
							type: "icon",
							icon: "wxi-plus",
							autowidth: true,
							label: _("Add dates"),
							click: () => this.AddBtnClickHandler(),
							css: "webix_transparent",
						},
						{},
					],
				},
				{
					batch: "added",
					cols: [
						{},
						{
							view: "button",
							type: "icon",
							icon: "wxi-trash",
							autowidth: true,
							label: _("Remove dates"),
							click: () => this.RemoveBtnClickHandler(),
							css: "webix_transparent",
						},
						{},
					],
				},
			],
			on: {
				onChange: (n, o, config) => {
					if (this.Compact) this.UpdateTaskTime();
					else {
						if (config == "user") this.UpdateTask();
					}
				},
			},
		};
		return form;
	}

	init() {
		this.State = this.getParam("state", true);
		this.Ops = this.app.getService("operations");
		this.Form = this.$$("form");

		// changing links via bars and dnd
		this.Tasks = this.app.getService("local").tasks();
		this.on(this.State.$changes, "selected", id => {
			if (id) this.FillData(id);
		});
		this.on(this.Tasks.data, "onStoreUpdated", (id, obj, mode) => {
			if (mode == "update" && id == this.State.selected) this.FillData(id);
		});
	}

	FillData(id) {
		const task = this.Tasks.getItem(id);
		if (task.planned_start) this.$$("form").showBatch("added");
		else this.$$("form").showBatch("none");
		this.Form.setValues(this.Tasks.getItem(id));
	}

	/**
	 * Prepares task  object for processing with respect to date-related changes
	 * @param {Object} vals - object with form values
	 * @returns {boolean} flag that indicates whether dates were updated or not
	 */
	PrepareDates(vals) {
		const f = (this._eventSource = this.Form.$eventSource.config.name);

		let updateDates = true;
		if (
			f == "planned_duration" ||
			(f == "planned_start" && vals.type == "project")
		)
			vals.planned_end = null;
		else if (f == "planned_end" || f == "planned_start")
			vals.planned_duration = null;
		else updateDates = false;

		return updateDates;
	}
	/**
	 * Update task time and duration on data change in compact form
	 */
	UpdateTaskTime() {
		const vals = this.Form.getValues();
		const updateDates = this.PrepareDates(vals);
		if (updateDates) {
			let mode = this._eventSource ? this._eventSource.split("_")[1] : null;
			if (mode == "duration") mode = null;

			this.Ops.updatePlannedTaskDuration(vals, mode);

			this.Form.blockEvent();
			this.Form.setValues(vals, true);
			this.Form.unblockEvent();
		}
	}
	/**
	 * Saves changes
	 * @returns {Promise} the result of a data operation (adding new event or updating)
	 */
	UpdateTask() {
		const id = this.State.selected;
		const vals = this.Form.getValues();
		if (!this.Compact) this.PrepareDates(vals);

		let mode = this._eventSource ? this._eventSource.split("_")[1] : null;
		if (mode == "duration") mode = null;
		this.Ops.updateTask(id, vals, mode, false, true);
	}
	AddBtnClickHandler() {
		const id = this.State.selected;
		const vals = this.Form.getValues();
		const obj = {
			planned_start: vals.start_date,
			planned_end: vals.end_date,
			planned_duration: vals.duration,
		};
		this.Ops.updateTask(id, obj, null, false, true);
	}
	RemoveBtnClickHandler() {
		const id = this.State.selected;
		webix
			.confirm(this._("Planned dates will be removed, are you sure?"))
			.then(() => {
				this.Ops.updateTask(
					id,
					{ planned_start: null, planned_end: null, planned_duration: null },
					null,
					false,
					true
				);
			});
	}
}
