import { JetView } from "webix-jet";

export default class TaskView extends JetView {
	config() {
		const _ = (this._ = this.app.getService("locale")._);
		const state = (this.State = this.getParam("state", true));

		const editButton = {
			view: "button",
			width: 130,
			label: _("Edit"),
			css: "webix_primary",
			click: () => this.EditTask(),
		};

		const aSkin = webix.skin.$active;
		const bar = {
			view: "toolbar",
			css: "webix_subbar",
			borderless: true,
			padding: {
				left: aSkin.layoutPadding.form - (aSkin.inputHeight - 20) / 2,
				right: aSkin.layoutPadding.form,
			},
			elements: [
				{
					view: "icon",
					icon: "wxi-close",
					click: () => this.Close(),
				},
				{},
				!state.readonly ? editButton : {},
			],
		};

		const info = {
			localId: "text",
			view: "template",
			css: "webix_gantt_task_info",
			template: obj => this.InfoTemplate(obj),
			borderless: true,
		};

		const deleteButton = {
			view: "button",
			label: _("Delete task"),
			css: "webix_danger webix_gantt_danger",
			align: "center",
			inputWidth: 330,
			click: () => {
				webix
					.confirm({
						container: this.app.getRoot().$view,
						title: _("Delete task"),
						text: _("The task will be deleted permanently, are you sure?"),
					})
					.then(() => this.DeleteTask());
			},
		};

		return {
			view: "proxy", // for borders
			body: {
				margin: 0,
				rows: [
					bar,
					{
						type: "form",
						borderless: true,
						rows: [info, !state.readonly ? deleteButton : {}],
					},
				],
			},
		};
	}

	init() {
		this.Local = this.app.getService("local");
		this.Tasks = this.Local.tasks();
		this.Text = this.$$("text");
		this.isResources = this.app.config.resources;

		this.on(this.State.$changes, "selected", id => {
			if (id) this.FillTemplate(id);
		});

		this.on(this.Tasks.data, "onStoreUpdated", (id, obj, mode) => {
			if (mode == "update" && id == this.State.selected) {
				this.FillTemplate(this.State.selected);
			}
		});

		// changing links via bars and dnd
		const links = this.Local.links();
		this.on(links.data, "onStoreUpdated", (_id, obj) => {
			if (obj) {
				const tid = this.State.selected;
				if (obj.target === tid || obj.source === tid) {
					this.FillTemplate(tid);
				}
			}
		});

		if (this.isResources) {
			this.on(this.Local.assignments().data, "onStoreUpdated", (id, obj) => {
				if (obj && obj.task == this.State.selected) this.FillTemplate(obj.task);
			});
		}
		this.on(this.State.$changes, "baseline", (v, old) => {
			if (!webix.isUndefined(old)) this.FillTemplate(this.State.selected);
		});
	}

	FillTemplate(id) {
		const item = webix.copy(this.Tasks.getItem(id));
		item.sources = this.Local.getLinks(id, "source");
		item.targets = this.Local.getLinks(id, "target");
		if (this.isResources && item.type == "task") {
			this.Local.getAssignments(id).then(d => {
				if (!this.getRoot()) return false;
				item.resources = d.sort(
					this.app.getService("operations").sortResources
				);
				this.Text.setValues(item);
			});
		} else this.Text.setValues(item);
	}

	EditTask() {
		this.State.edit = true;
	}

	DeleteTask() {
		this.app.getService("operations").removeTask(this.State.selected);
		this.Close();
	}

	Close() {
		this.State.$batch({
			edit: null,
			selected: null,
		});
	}

	InfoTemplate(obj) {
		if (!obj.start_date) return "";
		const _ = this._;
		let html = `<span class="webix_gantt_task_title">${obj.text ||
			_("(no title)")}</span><br><br>`;
		html += this.BaseTemplate(obj);
		html += this.LinkTemplate(obj);

		if (this.State.baseline && obj.planned_start) {
			html += this.BaselineTemplateChunk(obj, _("Planned dates"));
		}

		if (obj.details) {
			html += `<br><br><div class="webix_gantt_task_title">${_("Notes")}</div>
				<br><div class="webix_gantt_task_text">${obj.details.replace(
					/(?:\r\n|\r|\n)/g,
					"<br>"
				)}</div>`;
		}

		return html;
	}

	BaseTemplate(obj) {
		const _ = this._;
		let html = `<span class="webix_strong">${_(
			"Start date"
		)}</span>: ${this.DateFormat(obj.start_date)}<br>`;

		if (obj.type !== "milestone")
			html += `<span class="webix_strong">${_(
				"End date"
			)}</span>: ${this.DateFormat(obj.end_date)}<br>
			<span class="webix_strong">${_("Duration")}</span>: ${obj.duration}<br>
			<span class="webix_strong">${_("Progress")}</span>: ${obj.progress}%
		<br>`;
		return html;
	}

	LinkTemplate(obj) {
		let html = "";
		const _ = this._;
		if (obj.targets.length)
			html += this.InfoTemplateChunk(obj.targets, _("Predecessors"));
		if (obj.sources.length)
			html += this.InfoTemplateChunk(obj.sources, _("Successors"));
		if (obj.resources) {
			const single =
				this.isResources == "single" || this.app.config.resourceCalendars;
			html += this.ResourcesTemplateChunk(
				obj.resources,
				single ? _("Assignment") : _("Assignments")
			);
		}
		return html;
	}

	ResourcesTemplateChunk(arr, label) {
		if (!arr || !arr.length) return "";
		let maxWidth = 130;
		arr.forEach(r => {
			const s0 = webix.html.getTextSize(r.name, "webix_gantt_info_list_text");
			const s1 = webix.html.getTextSize(
				r.category,
				"webix_gantt_info_list_text webix_gantt_resource_section"
			);
			maxWidth = Math.max(maxWidth, s0.width, s1.width);
		});
		maxWidth = Math.min(200, maxWidth);
		let list = arr
			.map(r => {
				const h = this.app.getService("helpers");
				const avatar =
					"<div>" +
					h.resourceAvatar(r, "webix_gantt_person_avatar_big") +
					"</div>";
				const dpt =
					"<div class=' webix_gantt_info_list_text webix_gantt_resource_section' style='min-width:" +
					maxWidth +
					"px'>" +
					r.category +
					"</div>";
				const value =
					"<div class='webix_gantt_resource_section'>" +
					r.value +
					this._(h.getResourceUnit(r)) +
					"</div>";
				const top =
					"<div class='webix_gantt_info_list_text_row'><div class='webix_gantt_info_list_text' style='min-width:" +
					maxWidth +
					"px'>" +
					r.name +
					"</div>" +
					value +
					"</div>";
				const text = "<div  >" + top + dpt + "</div>";

				return (
					"<div class='webix_gantt_info_resource'>" + avatar + text + "</div>"
				);
			})
			.join("");
		return `<br><span class="webix_strong">${label}</span>:<br><ul class="webix_gantt_info_list_resources">${list}</ul>`;
	}

	InfoTemplateChunk(arr, label) {
		let res = arr
			.map(s => `<li>${s.text || this._("(no title)")}</li>`)
			.join("");
		return `<br><span class="webix_strong">${label}</span>:<br><ul>${res}</ul>`;
	}

	DateFormat(date) {
		return webix.i18n.longDateFormatStr(date);
	}
	BaselineTemplateChunk(obj, label) {
		const _ = this.app.getService("locale")._;

		let html =
			`${_("Start date")}: ${this.DateFormat(obj.planned_start)}<br>` +
			`${_("End date")}: ${this.DateFormat(obj.planned_end)}<br>` +
			`${_("Duration")}: ${obj.planned_duration}<br>`;
		return `<br><span class="webix_strong">${label}</span><br><div class="webix_gantt_info_list_planned">${html}</div>`;
	}
}
