/**
 * Prepares a sorting function with locale-based comparison
 * @param {string} by - column ID
 * @returns {function} sorting function
 */
export function sort(by) {
	return (a, b) =>
		a[by].localeCompare(b[by], undefined, {
			ignorePunctuation: true,
			numeric: true,
		});
}

/**
 * Check if two arrays contain same elements
 * @param {array} a - an array
 * @param {array} b - an array
 * @returns {boolean} true if two arrays contain same elements
 */
export function arrayEquals(a, b) {
	return a && a.length == b.length && a.every(item => b.includes(item));
}
