import { JetView } from "webix-jet";
import "../../helpers/numeditor";

export default class ResourcesView extends JetView {
	config() {
		const _ = (this._ = this.app.getService("locale")._);
		this.Local = this.app.getService("local");
		this.Helpers = this.app.getService("helpers");
		const conf = this.app.config;
		const single = (this.IsSingle =
			conf.resources == "single" || conf.resourceCalendars);

		const button = {
			cols: [
				{
					view: "button",
					type: "icon",
					icon: "wxi-plus",
					autowidth: true,
					localId: "addBtn",
					label: _("Add assignment"),
					click: () => this.AddBtnClickHandler(),
					css: "webix_transparent",
				},
				{},
			],
		};
		const table = {
			view: "treetable",
			css: "webix_gantt_resource_table webix_gantt_tree webix_gantt_form_tree",
			header: false,
			borderless: true,
			localId: "resources",
			autoheight: true,
			editable: true,
			editaction: "custom",
			scroll: false,
			hover: "webix_gantt_table_hover",
			rowHeight: Math.max(webix.skin.$active.rowHeight, 32),
			columns: [
				{
					id: "resource",
					css: "webix_gantt_title",
					fillspace: true,
					template: obj => this.ResourceTemplate(obj),
					editor: "richselect",
					suggest: {
						width: 250,
						css: "webix_gantt_select_editor_popup",
						padding: 0,
						point: 0,
						body: {
							width: 250,
							type: {
								height: Math.max(webix.skin.$active.listItemHeight, 32),
							},
							template: r =>
								single ? this.OptionTemplate(r) : this.EditorOptionTemplate(r),
						},
					},
				},

				{
					id: "value",
					width: 85,
					css: "webix_gantt_value",
					template: obj => this.ValueTemplate(obj),
					editFormat: function(v) {
						return parseFloat(v);
					},
					editParse: function(v) {
						v = parseFloat(v);
						return isNaN(v) ? 0 : v;
					},
					editor: "gantt_numeditor",
				},
				{
					id: "remove",
					width: 30,
					css: "webix_gantt_action",
					template: obj => this.DeleteIconTemplate(obj),
				},
			],
			on: {
				onItemClick: id => {
					if (!this.Table.getItem(id).$group && id.column != "remove") {
						this.Table.editRow(id);
						if (id.column == "value")
							this.Table.getEditor(id.row, "resource")
								.getPopup()
								.hide();
						this.Table.getEditor(id.row, id.column).focus();
					}
				},
				onBeforeEditStart: id => {
					const item = this.Table.getItem(id.row);
					if (item.$group) return false;
					if (id.column == "resource") {
						this.Table.addCellCss(
							id.row,
							id.column,
							"webix_gantt_select_editor"
						);
						const r = id && !single ? item.resource : null;
						this.FilterOptions(r);
					}
					return true;
				},
				onAfterEditStart: id => {
					if (id.column == "value") {
						const item = this.Table.getItem(id.row);
						const node = this.Table.getEditor(id.row, "value").getInputNode();
						const range = this.GetRange(item);
						if (range) this.ApplyEditorLimits(node, range);
						const step = this.GetValueStep(item);
						if (step) this.ApplyEditorStep(node, step);
					}
				},
				onBeforeEditStop: (state, editor) => {
					this.Table.removeCellCss(
						editor.row,
						"resource",
						"webix_gantt_select_editor"
					);
					if (state.value != state.old) {
						let obj = {};
						obj[editor.column] = state.value;
						if (editor.column == "value") {
							const item = this.Table.getItem(editor.row);
							const range = this.GetRange(item);
							if (range) this.ApplyValueRange(obj, range);
							else if (obj.value < 0) obj.value = 0;
							editor.getInputNode().value = obj.value;
						}

						this.UpdateAssignment(editor.row, obj);
					}
					return true;
				},
				onAfterLoad: function() {
					this.openAll();
				},
			},
			onClick: {
				webix_gantt_remove_icon: (e, id) => {
					webix
						.confirm({
							container: this.app.getRoot().$view,
							title: _("Delete assignment"),
							text: _("Are you sure to delete this assignment?"),
						})
						.then(() => this.RemoveAssignment(id.row));
				},
			},
		};
		if (!single)
			table.scheme = {
				$group: {
					by: "category",
				},
			};

		return {
			margin: webix.skin.$active.layoutMargin.form,
			padding: { top: webix.skin.$active.layoutMargin.form, bottom: 5 },
			rows: [table, button],
		};
	}

	init() {
		this.State = this.getParam("state", true);
		this.Ops = this.app.getService("operations");
		this.Helpers = this.app.getService("helpers");
		this.Table = this.$$("resources");
		this.Local = this.app.getService("local");
		this.Resources = this.Local.resources();
		if (!this.app.config.resources) return false;
		this.on(this.State.$changes, "selected", id => {
			if (id) this.FillData(id);
		});
	}

	/**
	 * Display assignments table
	 * @param {string} id - a task id
	 */
	FillData(id) {
		const item = this.Local.tasks().getItem(id);
		if (item.type != "task") return false;

		const loader = this.Local.getAssignments(id);
		loader.then(assignees => {
			if (!this.getRoot()) return false;
			this.Table.clearAll();
			this.Table.getColumnConfig("resource").collection = this.Resources;

			if (assignees.length) {
				this.Table.show();
				this.Table.parse(this.GetData(assignees));
				if (this.IsSingle) this.$$("addBtn").disable();
			} else {
				if (this.IsSingle) this.$$("addBtn").enable();
				this.Table.hide();
			}
		});
	}

	/**
	 * Get treetable data
	 * @param {array} assignments - an array of assignments data
	 * @returns {array} data array
	 */

	GetData(assignments) {
		assignments.sort(this.app.getService("operations").sortResources);
		return assignments;
	}

	/**
	 * Filters an option collection
	 * @param {string} id - the selected resource id
	 */
	FilterOptions(id) {
		let tableIds = [];
		const category = id ? this.Resources.getItem(id)["category_id"] : null;
		this.Table.data.each(item => {
			tableIds.push(item.resource);
		});

		this.Resources.filter(item => {
			return (
				(!category || item["category_id"] == category) &&
				(tableIds.indexOf(item.id) < 0 || (id && item.id == id))
			);
		});
	}

	/**
	 * Updates an assignment
	 * @param {string} id - an assignment id
	 * @param {object} obj - a hash of new assignment properties
	 */
	UpdateAssignment(id, obj) {
		// remove "focus" style for a richselect cell editor when
		// the editor is closed without event calls
		const node = this.Table.$view.querySelector(".webix_gantt_select_editor");
		if (node) {
			const rInd = node.getAttribute("aria-rowindex") * 1;
			this.Table.removeCellCss(
				this.Table.data.getIdByIndex(rInd - 1),
				"resource",
				"webix_gantt_select_editor"
			);
		}

		this.Ops.updateAssignment(id, obj).then(() => {
			this.CloseEditor(id, obj);
		});
	}

	/**
	 * Get range for unit values
	 * @param {object} item - an assignment item object
	 * @returns {array} an array with two numeric values [min,max]
	 */
	GetRange(item) {
		const resourceItem = this.Resources.getItem(item.resource);
		return this.Helpers.getResourceValueRange(resourceItem);
	}

	/**
	 * Apply limits to "value" property of a data object
	 * @param {object} obj - an object with a value property to change
	 * @param {array} range - a value range [min,max]
	 */
	ApplyValueRange(obj, range) {
		if (obj.value < range[0]) obj.value = range[0];
		else if (obj.value > range[1]) obj.value = range[1];
	}
	/**
	 * Apply limits to editor value
	 * @param {HTMLInputElement} node - an editor input
	 * @param {array} range - a value range [min,max]
	 */
	ApplyEditorLimits(node, range) {
		node.min = range[0];
		node.max = range[1];
	}
	/**
	 * Get set for unit values
	 * @param {object} item - an assignment item object
	 * @returns {number} a step value
	 */
	GetValueStep(item) {
		const resourceItem = this.Resources.getItem(item.resource);
		return this.Helpers.getResourceValueStep(resourceItem);
	}

	/**
	 * Apply changing step to editor value
	 * @param {HTMLInputElement} node - an editor input
	 * @param {number} step - changing step
	 */
	ApplyEditorStep(node, step) {
		node.step = step;
	}

	/**
	 * Closes editor and updated table row
	 * @param {string} id - an assignment id
	 * @param {object} obj - assignment properties
	 */
	CloseEditor(id, obj) {
		this.Table.blockEvent();
		this.Table.editCancel();
		this.Table.unblockEvent();

		this.Table.updateItem(id, obj);
	}

	/**
	 * Removes assignment
	 * @param {string} id - assignment id
	 */
	RemoveAssignment(id) {
		this.Ops.removeAssignment(id).then(() => {
			this.FillData(this.State.selected);
		});
	}

	/**
	 *  "Add" button click handler
	 */
	AddBtnClickHandler() {
		this.FilterOptions();
		webix.delay(() => {
			this.ShowPopup();
		});
	}

	/**
	 * Adds an assignment and a new row to "assignees" table
	 * @param {object} obj - assignment properties
	 */
	AddAssignment(obj) {
		if (this.IsSingle) this.$$("addBtn").disable();
		this.Ops.addAssignment({
			resource: obj.id,
			value: this.Helpers.getDefaultResourceValue(obj),
			task: this.State.selected,
		}).then(() => {
			this.FillData(this.State.selected);
		});
	}
	/**
	 * A template for a resource column
	 * @param {object} obj - a data item
	 * @returns {string} an html string of a column cell
	 */
	ResourceTemplate(obj) {
		if (obj.$group) return `<span class="webix_strong">${obj.category}</span>`;
		else if (obj.resource) {
			const item = this.Resources.getItem(obj.resource);
			return this.EditorTemplate(item);
		}
		return "";
	}
	/**
	 * The template for value column
	 * @param {object} obj - an assignment data object
	 * @returns {string} html string
	 */
	ValueTemplate(obj) {
		if (obj.$group) return "";
		const v = obj.value;
		return (
			(isNaN(v) ? this.Helpers.getDefaultResourceValue(obj) : v) +
			this.GetUnitName(obj)
		);
	}

	/**
	 * Get a unit name for a resource
	 * @param {object} obj - resource object
	 * @returns {string} a unit name
	 */
	GetUnitName(obj) {
		return this._(this.Helpers.getResourceUnit(obj));
	}

	/**
	 * The template for "delete" column
	 * @param {object} obj - assignment data object
	 * @returns {string} html string
	 */
	DeleteIconTemplate(obj) {
		if (obj.$group) return "";
		return "<span class='webix_icon wxi-trash webix_gantt_remove_icon'></span>";
	}

	/**
	 * Get popup configuration
	 * @returns {object} popup config
	 */
	GetPopupConfig() {
		return {
			view: "suggest",
			localId: "popup",
			width: 320,
			padding: 0,
			point: 0,
			fitMaster: false,
			borderless: true,
			css: "webix_gantt_select_editor_popup",
			body: {
				view: "list",
				minHeight: 28,
				type: {
					height: Math.max(webix.skin.$active.listItemHeight, 32),
				},
				data: this.Resources,
				template: obj => this.OptionTemplate(obj),
				on: {
					onItemClick: id => {
						this.AddAssignment(this.Resources.getItem(id));
						this.Popup.hide();
					},
				},
			},
		};
	}

	/**
	 * Show resources popup
	 */
	ShowPopup() {
		if (!this.Popup || !this.Popup.$view) {
			this.Popup = this.ui(this.GetPopupConfig());
			this.SetPopupPlaceholder();
		}

		this.Popup.show(this.$$("addBtn").$view, { x: 1 });
	}

	/**
	 * Set a placeholder for the "Add" popup placeholder
	 * @param {string} placeholder - a popup placeholder (optional)
	 */
	SetPopupPlaceholder(placeholder) {
		const list = this.Popup.getBody();
		list.$view.firstChild.setAttribute(
			"placeholder",
			placeholder || this._("No resources to add")
		);
	}

	/**
	 * A template for "Add assignment" popup options
	 * @param {object} obj - a data item
	 * @returns {string} an html string of an editor option
	 */
	OptionTemplate(obj) {
		const avatar = this.Helpers.resourceAvatar(obj);
		const name =
			"<div class='webix_gantt_editor_avatar_name'>" + obj.name + "</div>";
		const avatarStr = `<div class="webix_gantt_avatar_box_inline">${avatar +
			name}</div>`;
		const dpt = `<span class='webix_gantt_resource_section'>${obj.category}</span>`;
		return (
			"<div class='webix_gantt_avatar_option_box'>" + avatarStr + dpt + "</div>"
		);
	}

	/**
	 * Cell template
	 * @param {object} obj - resource object
	 * @returns {string} html string for resource editable cell
	 */
	EditorTemplate(obj) {
		const avatar = this.Helpers.resourceAvatar(obj);
		const name =
			"<div class='webix_gantt_editor_avatar_name'>" + obj.name + "</div>";
		const icon = "<span class='webix_icon wxi-menu-down'></span>";
		return `<div class="webix_gantt_avatar_box">${avatar} ${name}${icon}</div>`;
	}

	/**
	 * Popup option template for resource editor
	 * @param {object} obj - resource object
	 * @returns {string} html string for an option
	 */
	EditorOptionTemplate(obj) {
		const avatar = this.Helpers.resourceAvatar(obj);
		const name =
			"<div class='webix_gantt_editor_avatar_name'>" + obj.name + "</div>";
		return `<div class="webix_gantt_avatar_box">${avatar} ${name}</div>`;
	}
}
