import { arrayEquals } from "helpers/common";

export default class Grouping {
	/**
	 * @constructor
	 * @param {App} app - Jet App instance
	 */
	constructor(app) {
		this.app = app;
		this.local = app.getService("local");
		this.helpers = app.getService("helpers");
		this.closedResources = null;
	}

	/* Grouping for Tasks View*/

	/***
	 * Get tasks tree data (grouped by parent)
	 * @param {array} data - a data array
	 * @param {string} id - a branch id
	 * @returns {array} a data array for webix.TreeCollection
	 */
	getTreeData(data, id) {
		const tree = data.filter(a => a.parent == id);
		tree.forEach(a => {
			const temp = this.getTreeData(data, a.id);
			if (temp) a.data = temp;
		});

		return tree;
	}

	/* Grouping for Resource Diagram */

	/**
	 * Webix TreeCollection
	 * @typedef {Object} TreeCollection
	 */

	/**
	 * Collects data for resource diagram (hierarchical structure)
	 * @returns {TreeCollection}
	 */
	getRDCollection(force) {
		if (this._rdCollection && !force) return this._rdCollection;

		const tasks = this.local.tasks();
		const assignments = this.local.assignments();
		const resources = this.local.resources();
		if (!this._rdCollection) {
			this._rdCollection = new webix.TreeCollection({});
			webix.extend(this._rdCollection, webix.Group);

			this.syncRDWithData();
		} else {
			this._rdCollection.clearAll();
		}

		webix.promise
			.all([tasks.waitData, assignments.waitData, resources.waitData])
			.then(() => {
				const data = [];
				const scales = this.local.getScales();
				assignments.data.each(obj => {
					const res = this.getDiagramItemData(obj, scales);
					if (res) data.push(res);
				});
				this._rdCollection.parse(data);
				this.groupResourceDiagram();
			});

		return this._rdCollection;
	}

	/**
	 * Synchs RD collection with main collections and scale changes
	 */
	syncRDWithData() {
		this.app.on("onScalesUpdate", (v, o) => {
			if (this._rdCollection && this._rdCollection.data.order.length) {
				this.refreshTaskDiagram();

				if (v.minUnit !== o.minUnit) {
					this.updateUnitDurations();
					this.groupResourceDiagram();
				}
			}
		});
		const tasks = this.local.tasks();
		tasks.data.attachEvent("onStoreUpdated", (id, obj, mode) => {
			if (
				mode &&
				obj &&
				(mode == "update" ||
					mode == "delete" ||
					this.local.assignments().find(a => a.task === obj.id, true))
			) {
				this.updateTaskDiagram(obj, mode);
				this.groupResourceDiagram();
			}
		});
		const assignments = this.local.assignments();
		assignments.data.attachEvent("onStoreUpdated", (id, obj, mode) => {
			if (id) {
				this.refreshResourceDiagram(id, obj, mode);
				this.groupResourceDiagram();
			}
		});
	}

	updateUnitDurations() {
		const tasks = this.local.tasks();
		const assignments = this.local.assignments();
		const scales = this.local.getScales();
		this._rdCollection.data.each(obj => {
			if (obj.task) {
				const task = tasks.getItem(obj.task);
				const assignment = assignments.getItem(obj.id);
				if (task && assignment) {
					obj.duration = this.calculateUnitDuration(task, scales);
					obj.value = this.calculateLoad(
						{ ...task, ...assignment },
						scales.minUnit
					);
				}
			}
		});
	}

	/**
	 *
	 * @param {Object} task
	 */
	calculateUnitDuration(task, scales) {
		return scales.minUnit === "day"
			? task.duration
			: scales.diff(
					task.end_date,
					task.start_date,
					webix.Date.startOnMonday,
					false,
					true
			  );
	}

	/**
	 * Collects data on assignment from all related tables (tasks and resources) for resources diagram
	 * @param {Object} obj - assignment data object
	 * @returns {Object} all data about assignment
	 */
	getDiagramItemData(obj, scales) {
		const task = this.local.tasks().getItem(obj.task);

		if (task) {
			if (!this.local._isTaskVisible(task, true)) {
				this.helpers.updateTask(task, 0);
			}

			const resource = this.local.resources().getItem(obj.resource);
			const res = { ...task, ...resource, ...obj };
			res.duration = this.calculateUnitDuration(task, scales);
			res.value = this.calculateLoad(res, scales.minUnit);

			// diagram renders 1 workload markers per min unit
			res.$x = this.truncateX(res.$x, scales);

			return res;
		}

		return null;
	}

	/**
	 * Calculates workload per current min scale unit
	 * @param {Object} data - data object for resource diagram (at least must have values from tasks and resources)
	 * @param {string} unit - the min scale unit of the current scale ("day", "week", etc)
	 * @returns {Array} load per 1st and last units if they are not fully occupied, and a fully occupied unit load. e.g. [1,0,8]
	 */
	calculateLoad(data, unit) {
		const num = this.getUnitLoad(data);

		if (unit === "hour") return [0, 0, num / 24];

		if (unit === "day") return [0, 0, num];

		const load = [];

		let start = webix.Date.copy(data.start_date);
		const startUnitStart = this.helpers.getUnitStart(unit, start);
		let end = webix.Date.copy(data.end_date);
		const endUnitStart = this.helpers.getUnitStart(unit, end);
		let startUnitEnd;

		if (webix.Date.datePart(start, true) > startUnitStart) {
			startUnitEnd = this.helpers.addUnit(unit, startUnitStart, 1);
			if (end > startUnitEnd) {
				// find duration in days for the first not-fully occupied unit
				const days = this.findUnitDuration(start, startUnitEnd, data);
				load.push(days);
				start = startUnitEnd;
			} else load.push(0);
		} else load.push(0);

		if (
			!webix.Date.equal(startUnitStart, endUnitStart) &&
			webix.Date.datePart(end, true) >= endUnitStart
		) {
			// find duration in days for the last not fully-occupied unit
			const days = this.findUnitDuration(endUnitStart, end, data);
			load.push(days);
			end = endUnitStart;
		} else load.push(0);

		// find duration in days for a fully-occupied unit
		const plusUnit = this.helpers.addUnit(unit, start, 1);
		const days = this.findUnitDuration(
			start,
			new Date(Math.min(plusUnit, end)),
			data
		);
		load.push(days);

		return load.map(l => l * num);
	}

	/**
	 * Finds unit duration in days ( to calculate work load per init )
	 * @param start {Date} start date
	 * @param end {Date} end date
	 * @param data {object} data object
	 * @returns {number} days number
	 */
	findUnitDuration(start, end, data) {
		let days = this.helpers.getDifference("day", end, start);
		if (this.app.config.excludeHolidays) {
			let i,
				d = webix.Date.copy(start),
				n = days;
			for (i = 0; i < n; i++) {
				if (this.checkHoliday(d, data)) days--;
				webix.Date.add(d, 1, "day");
			}
		}
		return days;
	}

	/**
	 * Check if date is a holiday
	 * @param d {Date} date to check
	 * @param data {object} data object (resource calendar check)
	 * @returns {boolean} true if date is a holiday
	 */
	checkHoliday(d, data) {
		if (this.app.config.resourceCalendars) {
			const calendarId = this.local.resources().getItem(data.resource)
				.calendar_id;
			if (calendarId) {
				const calendar = this.local.calendars().getItem(calendarId);
				return this.helpers.isResourceHoliday(d, calendar);
			}
		}
		return this.app.config.isHoliday(d);
	}

	/**
	 * Returns resource load per unit
	 * @param {Object} data - data object from Resource Diagram collection
	 * @returns {number} load per unit (by default hours, but can be anything)
	 */
	getUnitLoad(data) {
		return data.value;
	}

	/**
	 * Cuts X coordinate down to unit start if precision setting was set for the scale,
	 * because diagram renders 1 workload marker per min unit in the middle
	 * @param {number} x - the X coordinate of a task bar on a date scale
	 * @param {Object} scales - the data object of current scales
	 * @returns {number} altered X
	 */
	truncateX(x, scales) {
		if (scales.precise !== false) {
			return Math.floor(x / scales.cellWidth) * scales.cellWidth;
		}
		return x;
	}

	/**
	 * Refreshes data in diagram collection if a resource was (un)assigned or load per day changed
	 * @param {(string|number)} id - the ID of an assignment record
	 * @param {Object} obj - data object of an assignment
	 * @param {string} mode - operation mode ("add", "update", "delete")
	 */
	refreshResourceDiagram(id, obj, mode) {
		const data = this._rdCollection;
		const scales = this.local.getScales();
		switch (mode) {
			case "update":
				if (data.exists(id)) {
					const task = this.local.tasks().getItem(obj.task);
					const resource = { ...this.local.resources().getItem(obj.resource) };
					delete resource.id;
					const value = this.calculateLoad({ ...task, ...obj }, scales.minUnit);
					data.updateItem(id, { ...obj, ...resource, value });
				}
				break;
			case "add":
				data.add(this.getDiagramItemData(obj, scales));
				break;
			case "delete":
				if (data.exists(id)) data.remove(id);
				break;
		}
	}

	/**
	 * Removed all related data records in diagram collection if an assigned task was removed
	 * @param {Object} task - the ID of a task
	 * @param {string} mode - operation type (add, delete, update)
	 */
	updateTaskDiagram(task, mode) {
		const data = this._rdCollection;
		const scales = this.local.getScales();

		if (mode === "delete") {
			this.pruneDiagram();
		} else if (mode === "add") {
			const rec = this.local.assignments().find(a => a.task === task.id, true);
			data.add(this.getDiagramItemData(rec, scales));
		} else if (mode === "update") {
			const assignments = data.find(a => a.task === task.id);
			assignments.forEach(a => {
				const oa = this.local.assignments().getItem(a.id);
				data.updateItem(a.id, {
					start_date: task.start_date,
					id: a.id,
					duration: this.calculateUnitDuration(task, scales),
					$x: this.truncateX(task.$x, scales),
					value: this.calculateLoad({ ...task, ...oa }, scales.minUnit),
				});
			});
		}
	}

	/**
	 * Cleans removed tasks from resource diagram
	 * (it's not enough to remove 1 removed task because the removed task can have kids,
	 * and they go together with their parent and there is no event about each of them)
	 */
	pruneDiagram() {
		const tasks = this.local.tasks();
		const toRemove = [];
		this._rdCollection.data.eachLeaf(0, d => {
			if (!tasks.exists(d.task)) toRemove.push(d.id);
		});
		if (toRemove.length) this._rdCollection.remove(toRemove);
	}

	/**
	 * Updates $x coordinates for tasks after scale changes
	 */
	refreshTaskDiagram() {
		const scales = this.local.getScales();
		this._rdCollection.data.each(a => {
			const task = this.local.tasks().getItem(a.task);
			if (task) a.$x = this.truncateX(task.$x, scales);
		});
	}

	/**
	 * Groups data for resource diagram
	 */
	groupResourceDiagram() {
		const collection = this._rdCollection;
		collection.group({
			by: obj => obj.name + "-" + obj.category,
			map: {
				resource_id: ["resource"],
				value: ["name"],
				name: ["name"],
				category: ["category"],
				avatar: ["avatar"],
				load: ["value", calculateLoad],
				duration: ["duration", collect], // num of workload markers
				units: ["value", collect], // number on a workload marker
				$x: ["$x", collect], // start of a task
				count: ["value", "count"],
				start_date: ["start_date", collect],
			},
		});
		collection.group(
			{
				by: "category",
				map: {
					value: ["category"],
					name: ["category"],
					duration: ["duration", "sum"],
					load: ["load", "sum"],
					count: ["count", "sum"],
				},
			},
			0
		);
	}

	/* Grouping for Resource View*/

	/**
	 * Resource-task collection (shown when "display" state property equals "resources")
	 * @returns {object} TreeCollection|webix.TreeCollection
	 */
	getResourceTree() {
		if (this._resourcesTree) return this._resourcesTree;

		this._resourcesTree = new webix.TreeCollection({
			on: {
				"data->onStoreUpdated": (id, obj, mode) => {
					id = mode == "update" || mode == "paint" ? id : null;
					this.refreshResourceTasks(id);
					this.local.refreshLinks();
				},
			},
			scheme: {
				$sort: {
					by: "start_date",
					dir: "asc",
					as: "int",
				},
			},
		});

		this._resourcesTree.parse(
			this.resourceTaskLoader().finally(() => {
				this.syncResourceTree();
			})
		);

		return this._resourcesTree;
	}

	/**
	 * Load task data and extend each task item with the "resources" property -
	 * an array of task resources or null
	 * @returns {Promise} promise that is resolved with a data array
	 */
	tasksWithResources() {
		return webix.promise
			.all([this.local.tasks().waitData, this.local.assignments().waitData])
			.then(() => {
				let data = [];
				this.local.tasks().data.each(
					t => {
						if (t.type == "task" || !t.type) {
							let task = Object.assign({}, t);
							const result = this.local
								.assignments()
								.data.find(a => a.task == task.id);
							task.resources = result.length
								? result.map(item => item.resource)
								: null;
							data.push(task);
						}
					},
					"",
					true
				);
				return data;
			});
	}

	/**
	 * tasks and resources loader
	 * @returns {Promise<[]>} promise that is resolved with a data array
	 * @private
	 */
	resourceTaskLoader() {
		const Ops = this.app.getService("operations");
		return webix.promise
			.all([this.tasksWithResources(), this.local.resources().waitData])
			.then(d => {
				let data = this.getResourceTaskData(d[0]);
				data.forEach(r => {
					Ops.setProjectData(r, null, r.data);
				});
				return data;
			});
	}

	/**
	 * Updates Resource TreeCollection, called on tasks() TreeCollection update
	 */
	refreshResourceTree() {
		if (this._resourcesTree.count()) {
			// get closed branches
			this.closedResources = [];
			this._resourcesTree.data.eachChild("0", item => {
				if (!item.open) this.closedResources.push(item.resources);
			});
		} else this.closedResources = null;
		this.resourceTaskLoader().then(data => {
			// reload data
			this._resourcesTree.clearAll();
			this._resourcesTree.parse(data);
			return data;
		});
	}

	syncResourceTree() {
		const tree = this._resourcesTree;
		this.local.tasks().data.attachEvent("onStoreUpdated", (id, obj, mode) => {
			if (mode == "update") {
				const inTree = tree.exists(id) && tree.getItem(id).type == "task";
				const becomesTask = obj.type == "task";

				if (inTree && becomesTask) {
					tree.updateItem(id, {
						text: obj.text,
						details: obj.details,
						start_date: obj.start_date,
						end_date: obj.end_date,
						duration: obj.duration,
						progress: obj.progress,
					});
					const pId = tree.getParentId(id);
					let item = tree.getItem(pId);
					if (item) {
						item = this.app.getService("operations").setProjectData(item, tree);
						tree.updateItem(pId, item);
					}
					return true;
				} else if (inTree ^ becomesTask) {
					this.refreshResourceTree();
				}
			} else {
				this.refreshResourceTree();
			}
		});
		this.local.assignments().data.attachEvent("onStoreUpdated", () => {
			this.refreshResourceTree();
		});
	}

	/**
	 * Get data for resource Tree collection ( tasks grouped by resources )
	 * @param {array} tasks - an tasks array where each item contains
	 * "resources" property (an array of ids or null)
	 * @returns {array} data array
	 */
	getResourceTaskData(tasks) {
		let data = [];
		let unassignedBranch = null;
		const Ops = this.app.getService("operations");

		tasks.forEach(t => {
			const { resources } = t;
			let item;
			if (!resources) item = unassignedBranch ? unassignedBranch : null;
			else item = data.find(item => arrayEquals(item.resources, resources));
			if (!item) {
				item = {
					id: resources ? webix.uid() : "unassigned",
					type: "project",
					text: "",
					data: [],
					// $group needed for faster checks
					$group: true,
					// restore open/closed state
					open: this.checkResourceOpen(resources),
				};
				item.resources = resources;
				if (resources)
					item.text = resources
						.map(id => this.local.resources().getItem(id))
						.sort(Ops.sortResources)
						.map(item => item.name)
						.join(", ");

				if (resources) data.push(item);
				else {
					item.id = "unassigned";
					unassignedBranch = item;
				}
			}
			item.data.push(webix.copy(t));
		});
		if (unassignedBranch) data.push(unassignedBranch);
		return data;
	}

	/**
	 * Set task(s) properties that are needed to define size and position
	 * @param {string} updID - task id (optional)
	 * @param {number} i - task index (optional)
	 */
	refreshResourceTasks(updID, i) {
		const tree = this._resourcesTree;
		if (!updID) {
			tree.data.order.forEach((id, i) => {
				this.refreshResourceTasks(id, i);
			});
		} else {
			const t = tree.getItem(updID);
			i = !webix.isUndefined(i) ? i : tree.getIndexById(updID);
			this.helpers.updateTask(t, i);
		}
	}
	/**
	 * Checks whether this resource group needs to be open
	 * @param {array} resources - array of resources in the group or null for Unassigned
	 * @returns {boolean} result of the check
	 */
	checkResourceOpen(resources) {
		if (!this.closedResources) return true;
		if (!resources) return this.closedResources.indexOf(null) == -1;
		return this.closedResources.find(arr => arrayEquals(arr, resources))
			? false
			: true;
	}
}

/**
 * An aggregation function for data grouping that collects column values (any) into an array
 * @param {string} property - the name of the data field (column)
 * @param {Array} data - the data objects of the items from the data set
 * @returns {Array} the resulting array
 */
function collect(property, data) {
	const ws = [];

	for (let i = 0; i < data.length; i++) {
		ws.push(property(data[i]));
	}

	return ws;
}

/**
 * @param {string} property - the name of the data field (column)
 * @param {Array} data - the data objects of the items from the data set
 * @returns {number}
 */
function calculateLoad(property, data) {
	let sum = 0;

	for (let i = 0; i < data.length; i++) {
		const units = property(data[i]);
		let duration = parseInt(data[i].duration);
		const last = units.length - 1;
		for (let j = 0; j < units.length; ++j) {
			const num = units[j];
			if (num) {
				sum += (j === last ? duration : 1) * num;
				duration--;
			}
		}
	}

	return sum;
}
