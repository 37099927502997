import { JetView } from "webix-jet";
import TableView from "./links";
import ResourcesView from "./resources";
import BaselineView from "./baseline";

export default class FormView extends JetView {
	config() {
		this.Compact = this.getParam("compact", true);
		const _ = this.app.getService("locale")._;
		this.isResources = this.app.config.resources;
		this.State = this.getParam("state", true);

		const aSkin = webix.skin.$active;
		const bar = {
			view: "toolbar",
			css: "webix_subbar",
			borderless: true,
			padding: {
				left: aSkin.layoutPadding.form - (aSkin.inputHeight - 20) / 2,
				right: aSkin.layoutPadding.form,
			},
			elements: [
				{
					view: "icon",
					icon: "wxi-close",
					click: () => this.Close(),
				},
				{},
				{
					view: "button",
					width: 130,
					css: "webix_primary",
					value: _("Done"),
					click: () => this.Done(),
				},
			],
		};

		const calendar = {
			css: "webix_gantt_datepicker",
			type: "calendar",
			//localId: "calendar",
			body: {
				height: 270,
				width: 250,
				icons: true,
				events: date => this.app.config.isHoliday(date) && "webix_cal_event",
			},
		};

		const types = [
			{ id: "task", value: _("Task") },
			{ id: "milestone", value: _("Milestone") },
		];
		if (!this.app.config.projects) {
			types.splice(1, 0, { id: "project", value: _("Project") });
		}

		let accItems = [];
		if (this.app.config.links)
			accItems.push({
				header: _("Related tasks"),
				body: TableView,
				collapsed: true,
			});
		if (this.isResources)
			accItems.push({
				localId: "resourcesItem",
				header:
					this.isResources == "single" || this.app.config.resourceCalendars
						? _("Assignment")
						: _("Assignments"),
				body: { $subview: ResourcesView, name: "resources" },
				collapsed: true,
				hidden: true,
			});
		if (this.State.baseline)
			accItems.push({
				localId: "baselineItem",
				header: _("Planned dates"),
				body: { $subview: BaselineView, name: "baseline" },
				collapsed: true,
				hidden: true,
			});

		const accordion = {
			multi: true,
			view: "accordion",
			borderless: true,
			type: "clean",
			margin: 10,
			css: "webix_gantt_accordion",
			rows: accItems,
		};

		const form = {
			view: "form",
			localId: "form",
			borderless: true,
			autoheight: false,
			scroll: true,
			elementsConfig: {
				labelPosition: "top",
			},
			elements: [
				{ view: "text", name: "text", label: _("Title") },
				{
					view: "richselect",
					localId: "types",
					name: "type",
					label: _("Type"),
					options: types,
					on: {
						onChange: v => this.ToggleControls(v),
					},
				},
				{
					view: "datepicker",
					name: "start_date",
					label: _("Start date"),
					suggest: webix.copy(calendar),
				},
				{
					view: "datepicker",
					name: "end_date",
					label: _("End date"),
					suggest: webix.copy(calendar),
				},
				{
					view: "counter",
					name: "duration",
					css: "webix_gantt_form_counter",
					min: 1,
					max: 1000,
					label: _("Duration"),
				},
				{
					view: "slider",
					name: "progress",
					title: webix.template("#value#%"),
					label: _("Progress"),
				},
				accordion,
				{
					view: "textarea",
					name: "details",
					label: _("Notes"),
					height: 150,
				},
			],
			on: {
				onChange: (n, o, config) => {
					if (this.Compact) this.UpdateTaskTime();
					else {
						if (config == "user")
							this.UpdateTask().then(() => {
								// redraw all bars because a branch may be closed or opened in tree
								if (
									this._eventSource === "type" &&
									config === "user" &&
									(n === "split" || o === "split")
								)
									this.Tasks.data.callEvent("onStoreUpdated", []);
							});
					}
				},
			},
		};

		return {
			view: "proxy", // for borders
			body: {
				margin: 0,
				padding: { bottom: 14 },
				rows: [bar, form],
			},
		};
	}

	init() {
		this.Ops = this.app.getService("operations");
		this.Local = this.app.getService("local");
		this.Tasks = this.Local.tasks();
		this.Form = this.$$("form");

		// fills form with data of selected and newly created task
		this.on(this.State.$changes, "selected", id => {
			if (id) {
				this.FillData(id);
			}
		});

		// updates for start and end dates of task while dnd happens and form is open
		this.on(this.Tasks.data, "onStoreUpdated", (id, obj, mode) => {
			if (mode == "update" && id == this.State.selected)
				this.FillData(this.State.selected);
		});

		// "resources" view shows only "task" items (disable "type" select for it)
		this.on(this.State.$changes, "display", v => {
			const typeSelect = this.$$("form").elements["type"];
			if (v == "tasks") typeSelect.enable();
			else if (v == "resources") typeSelect.disable();
		});
		if (this.app.config.resourceCalendars)
			this.on(this.Local.assignments().data, "onStoreUpdated", () =>
				this.SetResourceHolidays()
			);
		this.on(this.State.$changes, "baseline", (v, old) => {
			if (!webix.isUndefined(old))
				this.SetBaselineVisibility(this.Tasks.getItem(this.State.selected));
		});
	}

	/**
	 * Applies task selection
	 * @param {(string|number)} id - the ID of a task
	 */
	FillData(id) {
		const item = this.Tasks.getItem(id);

		const typeList = this.$$("types").getList();
		if (this.app.config.split && item.$count) {
			if (!typeList.exists("split"))
				typeList.add({
					id: "split",
					value: this.app.getService("locale")._("Split task"),
				});
		} else {
			if (typeList.exists("split")) typeList.remove("split");
		}

		if (this.app.config.projects) {
			this.LimitTypeOptions(item);
		}
		if (this.app.config.resourceCalendars) this.SetResourceHolidays();

		if (this.isResources) this.SetResourcesVisibility(item);
		if (this.State.baseline) this.SetBaselineVisibility(item);
		this.Form.setValues(item);
		this.Form.focus();
	}

	/**
	 * Hide/show the "Assignments" accordion item depending on a task type
	 * @param {object} item - a task data object
	 */
	SetResourcesVisibility(item) {
		const layout = this.$$("resourcesItem");
		if (item.type == "task") {
			if (!layout.isVisible()) {
				const sub = this.getSubView("resources");
				if (sub) sub.FillData(item.id);
				layout.show();
			}
		} else if (layout.isVisible()) layout.hide();
	}
	/**
	 * Hide/show the "Assignments" accordion item depending on a task type
	 * @param {object} item - a task data object
	 */
	SetBaselineVisibility(item) {
		const layout = this.$$("baselineItem");
		if (
			this.State.baseline &&
			(item.type == "task" || item.type == "project")
		) {
			if (!layout.isVisible()) {
				const sub = this.getSubView("baseline");
				if (sub) sub.FillData(item.id);
				layout.show();
			}
		} else if (layout.isVisible()) layout.hide();
	}

	/**
	 * Prohibits the user from selecting the type incompatible with other data and relations of a task
	 * @param {Object} item - the data object of a task
	 */
	LimitTypeOptions(item) {
		const typeSelect = this.Form.elements.type;
		if (item.type === "project") typeSelect.hide();
		else typeSelect.show();
	}

	/**
	 * Prepares task  object for processing with respect to date-related changes
	 * @param {Object} vals - object with form values
	 * @returns {boolean} flag that indicates whether dates were updated or not
	 */
	PrepareDates(vals) {
		const f = (this._eventSource = this.Form.$eventSource.config.name);

		let updateDates = true;
		if (f == "duration" || (f == "start_date" && vals.type == "project"))
			vals.end_date = null;
		else if (f == "end_date" || f == "start_date") vals.duration = null;
		else updateDates = false;

		return updateDates;
	}
	/**
	 * Update task time and duration on data change in compact form
	 */
	UpdateTaskTime() {
		const vals = this.Form.getValues();
		const updateDates = this.PrepareDates(vals);

		// expand branches with subtasks that are not split anymore
		if (this._eventSource === "type" && vals.type !== "split")
			vals.open = vals.opened = 1;

		const textUpdated = this.RemoveTagsFromText(vals);
		if (textUpdated) {
			this.Form.blockEvent();
			this.Form.setValues(vals, true);
			this.Form.unblockEvent();
		}

		if (updateDates) {
			const mode = this._eventSource ? this._eventSource.split("_")[0] : null;
			this.Ops.updateTaskDuration(vals, mode);

			this.Form.blockEvent();
			this.Form.setValues(vals, true);
			this.Form.unblockEvent();
		}
	}

	/**
	 * Removes HTML tags from text fields
	 * @param {Object} vals - the changed values from the form
	 * @returns {boolean} - true if there is a text field in the changed values, false otherwise
	 */
	RemoveTagsFromText(vals) {
		if (this._eventSource === "text" || this._eventSource === "details") {
			vals[this._eventSource] = (vals[this._eventSource] || "").replace(
				/(<[^>]+>|^\s+|\s+$)/gi,
				""
			);
			return true;
		}
		return false;
	}

	/**
	 * Saves changes
	 * @returns {Promise} the result of a data operation (adding new event or updating)
	 */
	UpdateTask() {
		const id = this.State.selected;
		const vals = this.Form.getValues();
		if (!this.Compact) {
			this.PrepareDates(vals);
			this.RemoveTagsFromText(vals);
		}

		const mode = this._eventSource ? this._eventSource.split("_")[0] : null;

		// expand branches with subtasks that are not split anymore
		if (this._eventSource === "type" && vals.type !== "split")
			vals.open = vals.opened = 1;

		this._inProgress = this.Ops.updateTask(id, vals, mode);

		this._inProgress.finally(() => (this._inProgress = null));
		return this._inProgress;
	}

	/**
	 * Switches end date, duration and progress controls and enables or disables them depending on the type of the task
	 * @param {string} v - the option chosen in the type selector: "project", "task", "milestone"
	 */
	ToggleControls(v) {
		this.Form.elements.end_date.show();
		this.Form.elements.duration.show();
		this.Form.elements.progress.show();
		this.Form.elements.end_date.enable();
		this.Form.elements.duration.enable();
		this.Form.elements.progress.enable();

		if (v == "project" || v == "milestone") {
			const action = v == "project" ? "disable" : "hide";
			this.Form.elements.end_date[action]();
			this.Form.elements.duration[action]();
			this.Form.elements.progress[action]();
		}
	}

	/**
	 * Handles clicks on the 'cross' icon and closes the form
	 * if there are unsaved changes, initiates saving after confirmation from the user
	 */
	Close() {
		if (this.Compact && this.Form.isDirty()) {
			const _ = this.app.getService("locale")._;
			webix
				.confirm({
					container: this.app.getRoot().$view,
					text: _("Save changes?"),
				})
				.then(() => this.Done(true), () => this.Back(true));
		} else {
			this.Back(true);
		}
	}

	/**
	 * Closes the form and either closes the whole right panel or shows "info"
	 * @param {Boolean} exit - if true, the right panel will be closed; otherwise, right panel stays, form closes, "info" shows up
	 */
	Back(exit) {
		if (exit)
			this.State.$batch({
				edit: null,
				selected: null,
			});
		else this.State.edit = null;
	}

	/**
	 * Finishes task editing and closes the form
	 * @param {Boolean} exit - if true, the right panel will be closed; otherwise, right panel stays, form closes, info shows up
	 */
	Done(exit) {
		if (this.Compact && this.Form.isDirty()) {
			if (this._inProgress)
				this._inProgress.then(() => {
					this.Back(exit);
				});
			else this.UpdateTask().then(() => this.Back(exit));
		} else this.Back(exit);
	}

	SetResourceHolidays() {
		let arr = [];
		const els = this.Form.elements;
		const h = this.app.getService("helpers");
		arr.push(els["start_date"], els["end_date"]);
		const calendar = this.Local.getTaskCalendar(this.State.selected);
		arr.forEach(el => {
			const c = el.getPopup().getBody();
			c.config.events = date =>
				(calendar
					? h.isResourceHoliday(date, calendar)
					: this.app.config.isHoliday(date)) && "webix_cal_event";
			c.refresh();
		});
	}
}
