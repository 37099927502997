export default class Backend {
	/**
	 * @constructor
	 * @param {App} app - Jet App instance
	 * @param {string} url - server url
	 */
	constructor(app, url) {
		this.app = app;
		this._url = url;
	}
	/**
	 * Returns absolute url based on relative one
	 * @param {string} path - relative url
	 * @returns {string} absolute url
	 */
	url(path) {
		return this._url + path;
	}
	/**
	 * Loads tasks
	 * @returns {promise} promise that resolves with tasks array
	 */
	tasks() {
		return webix.ajax(this.url("tasks")).then(res => res.json());
	}
	/**
	 * Loads links
	 * @returns {promise} promise that resolves with links array
	 */
	links() {
		return webix.ajax(this.url("links")).then(res => res.json());
	}
	/**
	 * Loads resources
	 * @returns {promise} promise that resolves with resources array
	 */
	resources() {
		return webix.ajax(this.url("resources")).then(res => res.json());
	}
	/**
	 * Loads resource categories
	 * @returns {promise} promise that resolves with categories data
	 */
	categories() {
		return webix.ajax(this.url("categories")).then(res => res.json());
	}
	/**
	 * Loads task-resource data
	 * @returns {promise} promise that resolves with array of task-resource assignments
	 */
	assignments() {
		return webix.ajax(this.url("assignments")).then(res => res.json());
	}
	/**
	 * Returns calendars collection for resources
	 * @returns {object} calendars Data Collection
	 */
	calendars() {
		return webix.ajax(this.url("calendars")).then(res => res.json());
	}
	/**
	 * Adds task
	 * @param {object} obj - object with task fields
	 * @param {string} mode - the relative position of the new task in a branch: "first", "last", "after"
	 * @param {(string|number)} parent - parent task id, can be "0" for root
	 * @returns {promise} promise that resolves with server response
	 */
	addTask(obj, mode, parent) {
		const inProgress = webix
			.ajax()
			.post(this.url("tasks"), { ...obj, mode, parent })
			.then(res => res.json());
		this.app.callEvent("backend:operation", [inProgress]);
		return inProgress;
	}
	/**
	 * Removes task
	 * @param {string, number} id - task id
	 * @returns {promise} promise that resolves with server response
	 */
	removeTask(id) {
		const inProgress = webix
			.ajax()
			.del(this.url("tasks/" + id))
			.then(res => res.json());
		this.app.callEvent("backend:operation", [inProgress]);
		return inProgress;
	}
	/**
	 * Updates task
	 * @param {(string|number)} id - task id
	 * @param {Object} obj - object with task fields
	 * @param {Boolean} split - flag that indicates that this is a splitting of a task
	 * @returns {Promise} promise that resolves with with server response
	 */
	updateTask(id, obj, split) {
		const inProgress = webix
			.ajax()
			.put(this.url("tasks/" + id + (split ? "/split" : "")), obj)
			.then(res => res.json());
		this.app.callEvent("backend:operation", [inProgress]);
		return inProgress;
	}

	/**
	 * Changes order of tasks
	 * @param {(string|number)} id - task id
	 * @param {Object} obj - task position
	 * @returns {Promise} promise that resolves with with server response
	 */
	reorderTask(id, obj) {
		const inProgress = webix
			.ajax()
			.put(this.url("tasks/" + id + "/position"), obj)
			.then(res => res.json());
		this.app.callEvent("backend:operation", [inProgress]);
		return inProgress;
	}
	/**
	 * Adds link
	 * @param {object} obj - object with link fields
	 * @returns {promise} promise that resolves with server response
	 */
	addLink(obj) {
		const inProgress = webix
			.ajax()
			.post(this.url("links"), obj)
			.then(res => res.json());
		this.app.callEvent("backend:operation", [inProgress]);
		return inProgress;
	}
	/**
	 * Updates link
	 * @param {string, number} id - link id
	 * @param {object} obj - object with link fields
	 * @returns {promise} promise that resolves with with server response
	 */
	updateLink(id, obj) {
		const inProgress = webix
			.ajax()
			.put(this.url("links/" + id), obj)
			.then(res => res.json());
		this.app.callEvent("backend:operation", [inProgress]);
		return inProgress;
	}
	/**
	 * Removes link
	 * @param {string, number} id - task id
	 * @returns {promise} promise that resolves with server response
	 */
	removeLink(id) {
		const inProgress = webix
			.ajax()
			.del(this.url("links/" + id))
			.then(res => res.json());
		this.app.callEvent("backend:operation", [inProgress]);
		return inProgress;
	}
	/**
	 * Adds a new resource assignment to a task
	 * @param {object} obj - an object with "task", "resource" and "value" fields
	 * @returns {promise} a promise that is resolved with an object of a resource task
	 */
	addAssignment(obj) {
		const inProgress = webix
			.ajax()
			.post(this.url("assignments"), obj)
			.then(res => res.json());
		this.app.callEvent("backend:operation", [inProgress]);
		return inProgress;
	}
	/**
	 * Updates resource assignment
	 * @param {string, number} id -  an id of a task resource record
	 * @param {object} obj - an object with record fields
	 * @returns {promise} a promise that is resolved with server response
	 */
	updateAssignment(id, obj) {
		const inProgress = webix
			.ajax()
			.put(this.url("assignments/" + id), obj)
			.then(res => res.json());
		this.app.callEvent("backend:operation", [inProgress]);
		return inProgress;
	}
	/**
	 * Removes resource assignment
	 * @param {string, number} id - an id of an assignment record
	 * @returns {promise} a promise that is resolved with server response
	 */
	removeAssignment(id) {
		const inProgress = webix
			.ajax()
			.del(this.url("assignments/" + id))
			.then(res => res.json());
		this.app.callEvent("backend:operation", [inProgress]);
		return inProgress;
	}
}
