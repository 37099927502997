import { JetView } from "webix-jet";

export default class ScalesView extends JetView {
	config() {
		return { view: "template", height: 20, css: "webix_gantt_scale" };
	}

	init() {
		this.State = this.getParam("state", true);
		this.Local = this.app.getService("local");
		const scales = this.Local.getScales();
		this.RenderScales(scales);

		this.on(this.app, "onScalesUpdate", scales => this.RenderScales(scales));
	}

	ready() {
		const view = this.getRoot();
		this.on(this.State.$changes, "left", x => view.scrollTo(x, null));
	}

	/**
	 * Renders gantt scales
	 * @param {Object} s - the object that stores the data of current scales
	 */
	RenderScales(s) {
		const view = this.getRoot();
		const html = s.rows
			.map(line => {
				const canMarkHolidays =
					(line.type === "hour" || line.type === "day") && line.step === 1;
				return (
					`<div class="webix_gantt_scale_row" style='height:${line.height}px;width:${s.width}px'>` +
					line.cells
						.map(cell => this.CellTemplate(cell, canMarkHolidays))
						.join("") +
					"</div>"
				);
			})
			.join("");

		view.config.height = s.height;
		view.setHTML(html);
		view.resize();
	}

	/**
	 * Returns the template string for a scale unit
	 * @param {Object} cell - a scale unit
	 * @param {boolean} canMarkHolidays - signals whether to add styling for holidays
	 * @returns {string} - the template string of the scale cell
	 */
	CellTemplate(cell, canMarkHolidays) {
		return `<div class="webix_gantt_scale_cell ${cell.css +
			(canMarkHolidays && this.app.config.isHoliday(cell.date)
				? "webix_gantt_holiday_scale"
				: "")}" style="width:${cell.width}px;">${this.FormatDate(
			cell.date,
			cell.format
		)}</div>`;
	}

	FormatDate(date, format) {
		if (typeof format === "string") format = webix.Date.dateToStr(format);
		return format(date);
	}
}
