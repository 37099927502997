import { JetView } from "webix-jet";
import { initDnD, switchCursor } from "../../helpers/dnd";

export default class BarsView extends JetView {
	config() {
		this.State = this.getParam("state", true);
		const _ = this.app.getService("locale")._;

		return {
			view: "abslayout",
			css:
				this.State.readonly || !this.app.config.links
					? "webix_gantt_readonly"
					: "",
			borderless: true,
			cells: [
				{
					view: "template",
					css: "webix_gantt_holidays",
					borderless: true,
					relative: true,
				},
				{
					view: "template",
					css: "webix_gantt_links",
					borderless: true,
					relative: true,
				},
				{
					view: "list",
					borderless: true,
					type: this.BarsType(_),
					css: `webix_gantt${webix.env.touch ? "_touch" : ""}_bars`,
					on: {
						onItemClick: id => this.ItemClickHandler(id),
					},
					tooltip: obj => this.GetTooltip(obj, _),
					scroll: "xy",
					relative: true,
				},
			],
		};
	}

	init(view) {
		const [holidays, links, bars] = view.getChildViews();
		this.Holidays = holidays;
		this.Links = links;
		this.Bars = bars;
		this.Ops = this.app.getService("operations");
		this.Helpers = this.app.getService("helpers");

		const local = (this.Local = this.app.getService("local"));
		const scales = local.getScales();
		const linkCollection = local.links();
		const ldata = (this.LinksData = linkCollection.data);
		this.Calendars = this.app.config.resourceCalendars;

		this.HandleScroll();
		this.HandleDrag(scales);
		this.HandleSelection(scales);
		this.InitMarkers();
		this.HandleHolidays(scales);

		this.on(this.State.$changes, "display", (v, old) => {
			// if the selected task type was changed, the form not closed and the display mode changed, we need to close the form and remove selection layer
			this.SyncData(true);
			if (old) {
				this.Local.refreshLinks();
				this.RefreshLinks();
				if (this.Calendars) this.RenderResourceHolidays();
			}
		});

		// leave here for app.refresh() calls (inc. from Locale plugin)
		this.DrawGrid(view, scales);
		if (this.Calendars) this.RenderResourceHolidays(scales);

		this.on(this.app, "onScalesUpdate", scales => {
			this.DrawGrid(view, scales);
			this.Bars.refresh();
			this.RefreshLinks();
		});

		this.on(this.VisibleTasks.data, "onStoreUpdated", (id, data, action) => {
			this.RefreshLinks();
			if (action !== "update") this.ApplySelection();
			if (!action && this.Calendars) this.RenderResourceHolidays();
		});
		this.on(ldata, "onStoreUpdated", () => {
			this.RefreshLinks();
		});
		this.on(this.State.$changes, "criticalPath", (v, o) => {
			if (!webix.isUndefined(o)) {
				this.Local.showCriticalPath(!v);
				this.RefreshLinks();
			}
		});
		this.on(this.State.$changes, "compact", () => {
			this.RefreshLinks();
		});
		if (this.Calendars)
			this.on(
				this.Local.assignments().data,
				"onStoreUpdated",
				(id, data, action) => {
					this.RenderResourceHolidays();
					if (this.app.config.excludeHolidays && action) {
						const task = this.Local.tasks().getItem(data.task);
						if (task) this.Ops.updateAssignedTaskDates(task);
					}
				}
			);
		this.on(this.State.$changes, "baseline", (v, o) => {
			if (!webix.isUndefined(o))
				this.Local.updateScaleMinMax(this.VisibleTasks);
		});
	}

	/**
	 * Sets action ot bar item click
	 * @param {(string|number)} id  - ID of the clicked item
	 */
	ItemClickHandler(id) {
		if (!this.Bars.getItem(id).$group)
			this.State.$batch({
				parent: null,
				selected: id,
			});
	}

	/**
	 * Paints a grid
	 * @param {view} Object - Webix view of bars chart
	 * @param {scales} Object - scales object as returned by this.Local.getScales()
	 */
	DrawGrid(view, scales) {
		const colors = { contrast: "#808080", dark: "#384047" };
		view.$view.style.backgroundImage = `url(${this.app
			.getService("helpers")
			.drawGridInner(
				scales.cellWidth,
				scales.cellHeight,
				colors[webix.skin.$name] || "#ccc"
			)})`;
		view.$view.style.marginTop = "0px";

		// sync grid to scroll position
		const state = this.app.getState();
		view.$view.style.backgroundPosition = `-${state.left}px -${state.top}px`;

		this.Resize(scales.width);
	}

	/**
	 * Loads data to the chart
	 * @param {Boolean} unselect - passed on 'display' change; if the selected task is not found on chart and this param is true, State.selected will be nulled in ApplySelection
	 */
	SyncData(unselect) {
		this.Bars.clearAll();
		this.VisibleTasks = this.Local.getVisibleTasksCollection();
		if (this.Calendars) {
			this.Local.taskCalendarMap().then(() => {
				this.Bars.sync(this.VisibleTasks);
			});
		} else this.Bars.sync(this.VisibleTasks);
		this.ApplySelection(unselect);
	}

	/**
	 * Returns a string with HTML of a task node content
	 * @param {Object} obj - task data
	 * @param {function} _ - the translator function of jet locale plugin, see https://webix.gitbook.io/webix-jet/part-ii-webix-jet-in-details/plugins#applying-the-locale
	 * @returns {string}
	 */
	BarsTemplate(obj, _) {
		const text = obj.text || (obj.$group ? _("Unassigned") : _("(no title)"));
		return obj.type == "milestone" ? `<span>${text}</span>` : text;
	}

	/**
	 * Returns all templates for bars
	 * @param {function} _ - the translator function of jet locale plugin, see https://webix.gitbook.io/webix-jet/part-ii-webix-jet-in-details/plugins#applying-the-locale
	 * @returns {Object} all templates for bars
	 */
	BarsType(_) {
		return {
			template: obj => {
				if (
					this.State.display === "tasks" &&
					obj.type === "split" &&
					obj.$data &&
					obj.$data.length
				) {
					const { height, width, left, top } = this.GetSplitBox(obj);
					let html = `<div class="webix_gantt_split_container" webix_s_id="${obj.id}" style="top:${top}px;left:${left}px;height:${height}px;width:${width}px;"></div>`;

					// split kids are not in the order
					const order = this.VisibleTasks.data.order;
					const isLast = order.length > 1 && obj.id == order[order.length - 1];
					obj.$data.forEach(kid => {
						html +=
							this.TemplateStart(kid, isLast, true) +
							this.BarsTemplate(kid, _) +
							this.TemplateEnd(kid);
					});
					return html;
				} else return this.BarsTemplate(obj, _);
			},
			templateStart: task => {
				if (
					this.State.display === "tasks" &&
					task.type === "split" &&
					task.$data &&
					task.$data.length
				)
					return "";
				const order = this.VisibleTasks.data.order;
				const isLast = order.length > 1 && task.id == order[order.length - 1];
				return this.TemplateStart(task, isLast);
			},
			templateEnd: task => {
				if (
					this.State.display === "tasks" &&
					task.type === "split" &&
					task.$data &&
					task.$data.length
				)
					return "";
				return this.TemplateEnd();
			},
		};
	}

	/**
	 * Gets the size and position parameters for a decorative div-element behind a split task
	 * @param {Object} obj - data of a split task
	 * @returns {Object} an object with top, left, height, and width of an element
	 */
	GetSplitBox(obj) {
		const last = obj.$data[0];
		const first = obj.$data[obj.$data.length - 1];
		const left =
			first.$x + (first.type == "milestone" ? Math.floor(first.$w / 2) : 0);
		const rwidth = last.type == "milestone" ? Math.floor(last.$w / 2) : last.$w;

		return {
			top: last.$y,
			left,
			height: last.$h,
			width: last.$x - left + rwidth,
		};
	}

	/**
	 * Returns the html for the first part of a task
	 * @param {Object} task - a task data object
	 * @param {Boolean} last - a flag that indicates that this task is in the last line of gantt
	 * @param {Boolean} split - a flag that indicates that this task is a child of a parent with "split" type
	 * @returns {string} HTML that defines how a task is displayed
	 */
	TemplateStart(task, last, split) {
		let w = task.$w,
			h = task.$h,
			diff = 0,
			progress = "";
		const css = this.BarCSS(task, last);
		const contentCss = this.ContentCss(task);
		if (task.type == "milestone") {
			w = h = Math.ceil(Math.sqrt(Math.pow(task.$w, 2) / 2));
			diff = Math.ceil((task.$w - w) / 2);
		} else {
			const drag =
				task.type == "task" ? this.DragProgressTemplate(task.progress) : "";
			progress = `<div class="webix_gantt_progress" style="width:${task.progress}%;">${drag}</div>`;
		}

		let baseline = "";
		if (
			this.State.baseline &&
			task.type !== "milestone" &&
			task.planned_start &&
			!split
		)
			baseline = this.TemplateBaseline(task);

		return (
			baseline +
			`<div webix_l_id="${task.id}" class="${css}" 
			style="left:${task.$x + diff}px;top:${task.$y +
				diff -
				(baseline ? 1 : 0)}px;width:${w}px;height:${h}px;" 
			data-id="${task.id}">
				<div class="webix_gantt_link webix_gantt_link_left"></div>
				${progress}
				<div class="webix_gantt_content ${contentCss}">`
		);
	}

	TemplateBaseline(t) {
		return `<div  
			class="webix_gantt_baseline_${t.type}" 
			style="left:${t.$x0}px;top:${t.$y + t.$h - 2}px;width:${t.$w0}px; " 
			data-baseid="${t.id}"></div>`;
	}

	/**
	 * Get task classname
	 * @param {Object} task - a task data object
	 * @param {Boolean} last - a flag that indicates that this task is in the last line of gantt
	 * @returns {string} a classname
	 */
	BarCSS(task, last) {
		let css = `webix_gantt_task_base webix_gantt_${
			task.type === "split" ? "task" : task.type
		}`;
		if (task.$critical) css += " webix_gantt_critical";
		if (task.$group) css += " webix_gantt_group";
		if (last) css += " webix_gantt_last";
		if (task.css && task.type != "milestone") css += " " + task.css;
		return css;
	}
	ContentCss(task) {
		return task.css && task.type == "milestone" ? " " + task.css : "";
	}
	/**
	 * Returns the html for the last part of a task
	 * @returns {string} HTML that defines how a task is displayed
	 */
	TemplateEnd() {
		return `</div><div class="webix_gantt_link webix_gantt_link_right"></div></div>`;
	}

	/**
	 * Returns a string with HTML of the progress drag marker
	 * @param {number} progress - the progress of a task (number from 0 to 100)
	 * @returns {string}
	 */
	DragProgressTemplate(progress) {
		return `<div class="webix_gantt_progress_drag" style="left:${progress}%">
			<span class="webix_gantt_progress_percent">${progress}</span>
		</div>`;
	}

	/**
	 * Extends the class with methods for DnD and handles cursor change for bars
	 */
	HandleDrag() {
		initDnD.call(this, this.Bars);

		this._mousemove_handler = webix.event(this.Bars.$view, "pointermove", e => {
			if (e.pointerType == "mouse") switchCursor(e, this.Bars);
		});
	}

	destroy() {
		this._mousemove_handler = webix.eventRemove(this._mousemove_handler);
		this._scroll_handler = webix.eventRemove(this._scroll_handler);

		const markers = this.app.config.markers;
		if (markers)
			for (let i = 0; i < markers.length; i++) {
				const m = markers[i];
				if (m.$interval) m.$interval = clearInterval(m.$interval);
			}
	}

	/**
	 * Finds and returns the SVG element with all links or the temp link that is rendered while the user is linking two tasks
	 * @param {Boolean} temp - if true, the method looks for the temp link
	 * @returns {SVGElement}
	 */
	GetLinks(temp) {
		return this.Links.$view.querySelector(
			`.webix_gantt_${temp ? "temp_line" : "lines"}`
		);
	}

	/**
	 * Handles scrolling
	 */
	HandleScroll() {
		this._scroll_handler = webix.event(this.Bars.$view, "scroll", ev => {
			const bars = ev.target;
			const top = Math.round(bars.scrollTop);
			const left = Math.round(bars.scrollLeft);

			this.holidaysContainer.style.height = 0 + "px";
			this.containerForMarkers.style.height = 0 + "px";

			if (this.app.config.links) {
				const lines = this.GetLinks();
				const temp = this.GetLinks(true);
				const attrs = {
					viewBox: `${left} ${top} ${bars.scrollWidth} ${bars.scrollHeight}`,
					width: bars.scrollWidth,
					height: bars.scrollHeight,
				};

				for (let key in attrs) {
					lines.setAttribute(key, attrs[key]);
					temp.setAttribute(key, attrs[key]);
				}
			}
			this.SetHolidaysScroll(bars.scrollHeight, left, top);

			this.containerForMarkers.style.height = bars.scrollHeight + "px";

			this.getRoot().$view.style.backgroundPosition = `-${left}px -${top}px`;
			this.State.$batch({ top, left });
		});

		this.on(this.State.$changes, "top", y => {
			this.Bars.scrollTo(this.State.left, y);
		});

		if (this.app.config.resources) {
			this.on(this.State.$changes, "left", v => {
				if (this.State.resourcesDiagram) this.Bars.scrollTo(v, this.State.top);
			});

			this.on(this.app, "rdiagram:resize", () => {
				this.holidaysContainer.style.height =
					this.Bars.$view.scrollHeight + "px";
				this.containerForMarkers.style.height =
					this.Bars.$view.scrollHeight + "px";
			});
		}
	}

	SetHolidaysScroll(height, x, y) {
		this.holidaysContainer.style.height = height + "px";
		this.holidaysContainer.style.left = -x + "px";
		this.holidaysContainer.style.top = -y + "px";
		if (this.Calendars) {
			this.resHolidaysContainer.style.height = height + "px";
			this.resHolidaysContainer.style.left = -x + "px";
			this.resHolidaysContainer.style.top = -y + "px";
		}
	}

	/**
	 * Handles selection
	 * @param {Object} scales - the current scales
	 */
	HandleSelection(scales) {
		this.selLine = webix.html.create("DIV", {
			class: "webix_gantt_bar_selection",
			style: `height:${scales.cellHeight}px;width:${scales.width}px`,
		});
		this.Bars.$view.insertBefore(this.selLine, this.Bars.$view.firstChild);

		this.on(this.State.$changes, "selected", () => this.ApplySelection());
	}

	/**
	 * Highlights the selected task
	 * @param {Boolean} unselect - passed by SyncData call on 'display' change; if the selected task is not found in current collection and this param is true, State.selected will be nulled
	 */
	ApplySelection(unselect) {
		const id = this.State.selected;
		let top = -100;
		if (id && this.Bars.data.order.length) {
			let ind = (top = this.Bars.getIndexById(id));
			const task = this.Bars.getItem(id);
			if (ind < 0) {
				// split subtasks will not be found in datastore order
				if (
					this.State.display == "tasks" &&
					task &&
					task.parent != 0 &&
					this.Bars.getItem(task.parent).type === "split"
				) {
					ind = this.Bars.getIndexById(task.parent);
				} else if (unselect && !this.Bars.exists(id)) {
					this.State.selected = null;
				}
			}
			top = ind * this.Local.getScales().cellHeight;

			this.ScrollToTask(task);
		}
		this.selLine.style.top = top + "px";
	}

	/**
	 * Scrolls the chart to make the task visible
	 * @param {Object} task - the data object of a task
	 */
	ScrollToTask(task) {
		if (task) {
			const bars = this.Bars;
			if (
				task.$x > bars.$width - this.Local.getScales().cellWidth ||
				task.$x < bars.$view.scrollLeft
			) {
				// to make it visible, -30px for better looks - not sticking to the tree
				this.State.left = task.$x - 30;
			}
		}
		this.Bars.scrollTo(this.State.left, this.State.top);
	}

	/**
	 * Creates and renders markers (today and custom ones if they are provided)
	 */
	InitMarkers() {
		this.containerForMarkers = webix.html.create("DIV", {
			class: "webix_gantt_markers",
			style: `height:${this.Bars.$view.scrollHeight}px`,
		});
		this.Bars.$view.insertBefore(
			this.containerForMarkers,
			this.Bars.$view.firstChild
		);

		this.RenderMarkers(this.app.config.markers);
		this.on(this.app, "onScalesUpdate", () =>
			this.RenderMarkers(this.app.config.markers)
		);
	}

	/**
	 * Renders markers (today and custom ones if they are provided)
	 * @param {Array} markers - all markers
	 */
	RenderMarkers(markers) {
		if (!markers) return;

		const html = [];
		for (let i = 0; i < markers.length; i++) {
			const m = markers[i];
			if (m.now) {
				m.start_date = new Date();
				if (!m.$interval)
					m.$interval = setInterval(
						item => {
							const node = this.containerForMarkers.querySelector(
								`[gantt_now="${item.$interval}"]`
							);
							node.style.left = this.GetMarkerPosition(new Date()) + "px";
						},
						5 * 60 * 1000,
						m
					);
			}
			html.push(this.MarkerTemplate(markers[i]));
		}
		this.containerForMarkers.innerHTML = html.join("");
	}

	/**
	 * Returns a string with HTML content of a marker
	 * @param {Object} obj - marker settings
	 * @returns {string}
	 */
	MarkerTemplate(obj) {
		const text = obj.text
			? `<span class="webix_gantt_marker_text">${obj.text}</span>`
			: "";

		return `<div
			gantt_now="${obj.$interval}"
			class="webix_gantt_marker ${obj.css || ""}"
			style="left:${this.GetMarkerPosition(obj.start_date)}px">${text}</div>`;
	}

	/**
	 * Calculates new X position of a marker based on its date
	 * @param {Date} date - the date from marker settings
	 * @returns {number}
	 */
	GetMarkerPosition(date) {
		const { start, end, cellWidth, diff, minUnit } = this.Local.getScales();
		const astart = this.Helpers.getUnitStart(minUnit, start);

		return date < start || date > end
			? -100
			: Math.round(
					diff(date, astart, webix.Date.startOnMonday, true) * cellWidth
			  );
	}

	/**
	 * Returns a string with SVG with all links or a temp link
	 * @param {string} lines - all lines as SVG polylines
	 * @param {string} css - the name of CSS class for SVG (by default 'webix_gantt_lines' or 'webix_gantt_temp_line')
	 * @returns {string}
	 */
	LinksTemplate(lines, css) {
		const bars = this.Bars.$view;

		return `<svg
			class="${css}"
			viewBox="${this.State.left} ${this.State.top} ${bars.scrollWidth} ${bars.scrollHeight}"
			width="${bars.scrollWidth}"
			height="${bars.scrollHeight}"
		>${lines}</svg>`;
	}

	/**
	 * Re-renders links
	 */
	RefreshLinks() {
		const lines = [];
		// check to skip multiple re-rendering initially
		if (this.LinksData.count() && this.VisibleTasks.count()) {
			const links = this.LinksData;
			links.order.each(id => {
				const link = links.getItem(id);
				if (link.$p) {
					let critical =
						this.State.criticalPath && this.Local.isLinkCritical(link);
					lines.push(
						`<polyline data-id="${id}" ${
							critical ? "class='webix_gantt_line_critical'" : ""
						} points="${link.$p}" />`
					);
				}
			});
		}

		const html =
			this.LinksTemplate(lines.join(""), "webix_gantt_lines") +
			this.LinksTemplate('<polyline points="" />', "webix_gantt_temp_line");
		this.Links.setHTML(html);
	}

	/**
	 * Refreshes links of a task
	 * @param {string, number} tid - the ID of a task
	 */
	RefreshTaskLinks(tid) {
		this.LinksData.find(a => a.source == tid || a.target == tid).forEach(
			obj => {
				const l = this.Links.$view.querySelector(`[data-id="${obj.id}"]`);
				if (l) {
					const s = this.VisibleTasks.getItem(obj.source);
					const e = this.VisibleTasks.getItem(obj.target);
					this.Helpers.updateLink(obj, s, e);
					l.setAttribute("points", obj.$p);
				}
			}
		);
	}

	/**
	 * Changes the width of the root component (list with bars)
	 * @param {number} width
	 */
	Resize(width) {
		const area = this.Bars.$view.querySelector(".webix_scroll_cont");
		area.style.width = width + "px";
		area.style.minHeight = "1px";

		if (this.selLine) this.selLine.style.width = width + "px";
	}

	/**
	 * Handles actions with tasks
	 * @param {Object} obj - settings of an action
	 * @returns {Promise} - the result of asynchronous operations or an empty promise
	 */
	Action(obj) {
		const ops = this.app.getService("operations");
		/**
		 * Some actions invoke server-side request
		 * therefore we need show progress bar for them
		 */
		let inProgress = null;

		if (obj.action === "update-task-time") {
			if (obj.time) {
				inProgress = ops.updateTaskTime(obj.id, obj.mode, obj.time);
			} else {
				this.Local.refreshTasks(obj.id);
				if (this.State.display === "resources") {
					this.VisibleTasks.refresh(obj.id);
				}
				this.RefreshTaskLinks(obj.id);
			}
		} else if (obj.action === "update-task-progress") {
			if (!webix.isUndefined(obj.progress)) {
				inProgress = ops.updateTask(obj.id, { progress: obj.progress });
			} else {
				const task = this.VisibleTasks.getItem(obj.id);
				this.Bars.render(obj.id, task, "paint");
			}
		} else if (obj.action === "drag-task") {
			const task = this.VisibleTasks.getItem(obj.id);
			task.$x = parseInt(obj.left);
			task.$w = parseInt(obj.width);

			this.RefreshTaskLinks(obj.id);
		} else if (obj.action === "add-link") {
			const { source, target, type } = obj;
			inProgress = ops.addLink({ source, target, type });
		} else if (obj.action === "temp-link") {
			const { start, end } = obj;
			const link = this.GetLinks(true).firstChild;

			if (!start) {
				link.setAttribute("points", "");
			} else {
				const { left, top, p } = this.app
					.getService("helpers")
					.newLink(this.Links.$view.getBoundingClientRect(), start, end);
				const shift = [left, top];
				const points = p
					.split(",")
					.map((a, i) => a * 1 + shift[i % 2])
					.join(",");

				link.setAttribute("points", points);
			}
		} else if (obj.action === "add-task") {
			const scales = this.Local.getScales();
			const unit = scales.precise
				? this.Helpers.getSmallerUnit(scales.minUnit)
				: scales.minUnit;
			const start = this.Helpers.addUnit(unit, scales.start, obj.dates.start);
			const end = this.Helpers.addUnit(unit, start, obj.dates.end);
			this.app.callEvent("task:add", [obj.id, { start, end }]);
		} else if (
			obj.action === "split-resize" &&
			this.State.display !== "resources"
		) {
			const task = this.VisibleTasks.getItem(obj.id);
			if (task.parent != 0) {
				const parent = this.VisibleTasks.getItem(task.parent);
				if (parent.type === "split") {
					const splitCont = this.Bars.$view.querySelector(
						`[webix_s_id="${task.parent}"]`
					);
					parent.$data.sort((a, b) => b.$x - a.$x);
					const { width, left } = this.GetSplitBox(parent);
					splitCont.style.width = `${width}px`;
					splitCont.style.left = `${left}px`;
				}
			}
		}

		return inProgress || webix.promise.resolve();
	}

	/**
	 * Adds the layer for holiday highlighting and renders it
	 * @param {Object} scales - current scales
	 */
	HandleHolidays(scales) {
		let html =
			"<div class='webix_gantt_bar_holidays' style='height:" +
			this.Bars.$view.scrollHeight +
			"px'></div>";
		if (this.Calendars)
			html +=
				"<div class='webix_gantt_bar_resource_holidays' style='height:" +
				this.Bars.$view.scrollHeight +
				"px'></div>";
		this.Holidays.setHTML(html);
		this.holidaysContainer = this.Holidays.$view.querySelector(
			".webix_gantt_bar_holidays"
		);
		this.resHolidaysContainer = this.Holidays.$view.querySelector(
			".webix_gantt_bar_resource_holidays"
		);
		this.RenderHolidays(scales);

		this.on(this.app, "onScalesUpdate", scales => this.RenderHolidays(scales));
		this.on(this.app.getRoot(), "onViewShow", () => {
			this.refresh();
		});
	}

	EachScaleDate(scales, func) {
		if (scales.minUnit === "day" || scales.minUnit === "hour") {
			let date = webix.Date.copy(scales.start);

			let end = webix.Date.dayStart(scales.end);
			if (!(scales.end - end)) {
				end = webix.Date.add(end, -1, "day", true);
			}

			let hourOffset = scales.start.getHours();

			while (date <= end) {
				func.call(this, date, hourOffset);
				date = webix.Date.add(webix.Date.dayStart(date), 1, "day", true);
				hourOffset = webix.Date.equal(date, end)
					? 24 - Math.ceil(webix.Date.timePart(scales.end) / 60 / 60)
					: 0;
			}
		}
	}
	/**
	 * Renders holiday highlighting
	 * @param {Object} scales - current scales
	 */
	RenderHolidays(scales) {
		if (this.holidaysContainer) {
			let html = "";
			this.EachScaleDate(scales, (date, hourOffset) => {
				if (scales.isHoliday(date)) {
					html += this.HolidayTemplate(scales, date, hourOffset);
				}
			});
			this.holidaysContainer.innerHTML = html;

			if (this.Calendars) this.RenderResourceHolidays(scales);
		}
	}
	MarkResourceHolidays(scales, id, calendar) {
		let index = this.Bars.getIndexById(id);
		if (index < 0) return "";
		let html = "",
			n = 0,
			dateStart = null;

		this.EachScaleDate(scales, (date, hourOffset) => {
			const isHoliday = scales.isHoliday(date);
			if (this.Helpers.isResourceHoliday(date, calendar)) {
				if (!isHoliday) {
					if (!n) dateStart = date;
					n++;
				} else if (n) {
					html += this.ResourceHolidayTemplate(
						scales,
						dateStart,
						hourOffset,
						index,
						n
					);
					n = 0;
				}
			} else if (isHoliday) {
				if (n) {
					html += this.ResourceHolidayTemplate(
						scales,
						dateStart,
						hourOffset,
						index,
						n
					);
					n = 0;
				}
				html += this.ResourceWorkDayTemplate(scales, date, hourOffset, index);
			}
		});
		return html;
	}
	RenderResourceHolidays(scales) {
		if (this.resHolidaysContainer) {
			scales = scales || this.Local.getScales();
			this.Local.taskCalendarMap().then(data => {
				let html = "";
				if (data)
					for (let taskId in data)
						html += this.MarkResourceHolidays(scales, taskId, data[taskId]);
				this.resHolidaysContainer.innerHTML = html;
			});
		}
	}
	/**
	 * Returns a size and position of a date column / cell
	 * @param {Object} scales - the current scales
	 * @param {Date} date - the date
	 * @param {number} hourOffset - the hours of the scale start date
	 * @param {number} i - a task / row index ( optional )
	 * @returns {object}
	 */
	GetDateBox(scales, date, hourOffset, i) {
		const astart = this.Helpers.getUnitStart(scales.minUnit, scales.start);
		const x = Math.round(scales.diff(date, astart) * scales.cellWidth);
		const w =
			(scales.minUnit === "hour" ? 24 - hourOffset : 1) * scales.cellWidth;
		let box = { x, w };
		if (i || i === 0) {
			box.y = scales.cellHeight * i;
			box.h = scales.cellHeight;
		}
		return box;
	}
	/**
	 * Returns a string with HTML of 1 holiday highlight element
	 * @param {Object} scales - the current scales
	 * @param {Date} date - the date of this element
	 * @param {number} hourOffset - the hours of the scale start date
	 * @returns {string}
	 */
	HolidayTemplate(scales, date, hourOffset) {
		const { x, w } = this.GetDateBox(scales, date, hourOffset);
		const style = `left:${x}px;width:${w}px;`;
		return "<div class='webix_gantt_holiday' style='" + style + "'></div>";
	}
	/**
	 * Returns a string with HTML of holiday unit
	 * @param {Object} scales - the current scales
	 * @param {Date} date - the start date of this element
	 * @param {number} hourOffset - the hours of the scale start date
	 * @param {number} i - a task index
	 * @param {number} n - a number of holiday dates to include
	 * @returns {string}
	 */
	ResourceHolidayTemplate(scales, date, hourOffset, i, n) {
		const { x, y, w, h } = this.GetDateBox(scales, date, hourOffset, i);
		const style = `left:${x}px;width:${w * n}px;top:${y}px;height:${h}px;`;
		return "<div class='webix_gantt_holiday' style='" + style + "'></div>";
	}
	/**
	 * Returns a string with HTML of work day unit
	 * @param {Object} scales - the current scales
	 * @param {Date} date - the date of this element
	 * @param {number} hourOffset - the hours of the scale start date
	 * @param {number} i - a task index
	 * @returns {string}
	 */
	ResourceWorkDayTemplate(scales, date, hourOffset, i) {
		const { x, y, w, h } = this.GetDateBox(scales, date, hourOffset, i);
		const style = `left:${x + 0.2}px;width:${w - 1.2}px;top:${y}px;height:${h -
			1}px;`;
		return "<div class='webix_gantt_work_day' style='" + style + "'></div>";
	}
	/**
	 * Returns a string with HTML content of a tooltip for tasks
	 * @param {Object} obj - task data
	 * @param {function} _ - the translator function of jet locale plugin, see https://webix.gitbook.io/webix-jet/part-ii-webix-jet-in-details/plugins#applying-the-locale
	 * @returns {string}
	 */
	GetTooltip(obj, _) {
		const parser = webix.i18n.longDateFormatStr;
		let tip = `${obj.text || _("(no title)")}<br>
			<br>${_("Start date")}: ${parser(obj.start_date)}`;

		if (obj.type != "milestone") {
			tip += `<br>${_("End date")}: ${parser(obj.end_date)}
			<br>${_("Lasts")} ${obj.duration} ${obj.duration > 1 ? _("days") : _("day")}`;
		}
		return tip;
	}
}
