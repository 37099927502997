import { JetView } from "webix-jet";
import { sort } from "../../helpers/common";

export default class TreeView extends JetView {
	config() {
		this.State = this.getParam("state", true);
		const scales = this.app.getService("local").getScales();
		const _ = (this._ = this.app.getService("locale")._);
		const compact = (this.Compact = this.getParam("compact", true));

		const columns = [
			{
				id: "name",
				css: "webix_gantt_title",
				header: compact ? "<span class='webix_icon gti-menu'>" : _("Name"),
				template: (obj, common) => this.TreeTemplate(obj, common),
				fillspace: true,
				sort: compact ? "" : sort("name"),
				minWidth: compact ? 44 : 150,
			},
			{
				id: "load",
				header: _("Hours"),
				hidden: compact,
				batch: "hours",
				sort: "int",
				minWidth: 50,
				resize: false,
			},
			{
				id: "count",
				header: _("Tasks"),
				batch: "tasks",
				sort: "int",
				minWidth: 50,
				hidden: true,
				resize: false,
			},
		];

		const skin = webix.skin.$name;
		const headerCss =
			skin == "material" || skin == "mini" ? "webix_header_border" : "";
		const tree = {
			view: "treetable",
			css: "webix_gantt_resdiagram_tree " + headerCss,
			prerender: true,
			borderless: skin === "contrast",
			width: compact ? 44 : this.State.treeWidth,
			rowHeight: scales.cellHeight,
			headerRowHeight: scales.height,
			scroll: "xy",
			scrollAlignY: false,
			sort: true,
			resizeColumn: {
				headerOnly: true,
				size: 10,
			},
			columns,
			tooltip: () => "",
			on: {
				onViewResize: () => {
					this.State.treeWidth = this.Tree.$width;
				},
				onAfterOpen: id => this.ToggleBranch(id, 1),
				onAfterClose: id => this.ToggleBranch(id, 0),
				onAfterSort: (by, dir, as) => this.SortTasks(by, dir, as),
				onColumnResize: (id, v, o, user) => this.NormalizeColumns(id, user),
			},
			onClick: {
				"gti-menu": () => this.ToggleColumns(),
			},
			filterMode: {
				showSubItems: false,
				level: 0,
			},
		};

		const ls = (this.LocalState = this.getParam("localState"));
		tree.on["onScrollY"] = tree.on["onSyncScroll"] = tree.on[
			"onAfterScroll"
		] = function() {
			ls.top = this.getScrollState().y;
		};
		return tree;
	}

	init(view) {
		this.Tree = view;
		this.Helpers = this.app.getService("helpers");
		this.Local = this.app.getService("local");
		this.RDCollection = this.app.getService("grouping").getRDCollection();
		this._unit = "hours";
		this._collapsed = true;

		this.on(this.LocalState.$changes, "top", y => {
			this.Tree.scrollTo(null, y);
		});

		this.on(this.Tree.data, "onSyncApply", () => this.RestoreTreeState());

		view.sync(this.RDCollection, function() {
			this.filter(t => t.$group);
		});

		this.on(this.State.$changes, "treeWidth", v => {
			if (!this.Compact && view.$width !== v) {
				view.define({ width: v });
				view.resize();
			}
		});

		this.on(this.app, "rdiagram:unit:change", u => this.ShowInUnits(u));

		// resetting headerRowHeight
		this.on(this.app, "onScalesUpdate", scales => {
			const col = this.Tree.columnId(0);
			this.Tree.getColumnConfig(col).header[0].height = scales.height;
			this.Tree.refreshColumns();
		});
	}

	/**
	 * Opens/closes same branches as were opened/closed before updates
	 */
	RestoreTreeState() {
		if (this._treeOpened && this._treeOpened.length) {
			const t = this._treeOpened;
			const root = this.Tree.data.getBranch("0");
			root.forEach(b => {
				const ind = root.indexOf(b);
				b.open = !t.indexOf(ind);
				this.Tree.data.callEvent("onStoreUpdated", [b.id, 0, "branch"]);
			});
		} else this.Tree.openAll();
	}

	/**
	 * Defines the look of a tree item
	 * @param {Object} obj - data object of a tree item
	 * @param {Object} common - type object of tree
	 */
	TreeTemplate(obj, common) {
		const compact = this.Compact && this._collapsed;
		if (obj.$level == 1)
			return compact
				? common.icon(obj)
				: common.treetable(obj, common) + obj.category;

		if (compact) return this.Helpers.resourceAvatar(obj);
		return common.space(obj) + this.Helpers.resourceAvatar(obj) + obj.name;
	}

	/**
	 * Shows total workload in tasks or hours
	 * @param {string} u - "tasks" or "hours"
	 */
	ShowInUnits(u) {
		this._unit = u;
		const tree = this.Tree;
		const cols = tree.config.columns;
		const lastFillspace = cols[cols.length - 1].fillspace;
		if (!this.Compact || !this._collapsed) tree.showColumnBatch(u);
		// columns normalizing
		this.SetColumns(0, lastFillspace);
	}

	/**
	 * Adjusts columns after resizing
	 * @param {string} id - ID of the column being resized
	 * @param {boolean} user - defines whether resizing was triggered by user or API
	 */
	NormalizeColumns(id, user) {
		if (!user) return;
		this.SetColumns(this.Tree.getColumnIndex(id), true);
	}

	SetColumns(index, fillspace) {
		const columns = this.Tree.config.columns;
		const last = columns.length - 1;
		if (index === 0) {
			columns[last].fillspace = fillspace;
			columns[last].width = 0;
			columns[last].resize = false;
		} else if (index === last) {
			columns[0].fillspace = true;
			columns[0].width = 0;
		}
		this.Tree.refreshColumns();
	}

	/** Shows and hides columns in a compact mode */
	ToggleColumns() {
		// [todo] toggle tree in compact code
		const tree = this.Tree;
		const lv = tree.isColumnVisible("load");
		const cv = tree.isColumnVisible("count");
		const vis = (this._collapsed = lv || cv);

		if (vis) {
			if (lv) tree.hideColumn("load");
			if (cv) tree.hideColumn("count");
		} else {
			if (this._unit === "hours") tree.showColumn("load");
			else tree.showColumn("count");
		}

		tree.config.width = vis ? 44 : 0;
		const nameCol = tree.config.columns[0];
		nameCol.minWidth = vis ? 44 : 150;
		tree.resize();
		tree.refreshColumns();

		this.app.callEvent("rdiagram:tree:toggle", [vis]);
	}

	/**
	 * Hides/Shows the lines of the diagram for the closed/opened tree branch
	 * @param {number} id - the ID of a branch
	 * @param {Number} v - the state of a branch (1 - opened, 0 - closed)
	 */
	ToggleBranch(id, v) {
		const root = this.Tree.data.getBranch("0");
		const opened = this.Tree.getState().open.filter(i => {
			return root.find(o => o.id == i);
		});
		this._treeOpened = opened.map(id => this.Tree.getIndexById(id));

		this.app.callEvent("rdiagram:branch:toggle", [id, v]);
	}

	SortTasks(by, dir, as) {
		this.app.callEvent("rdiagram:sort", [by, dir, as]);
	}
}
