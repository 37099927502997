webix.editors.gantt_numeditor = webix.extend(
	{
		render: function() {
			const node = webix.editors.text.render.call(this);
			const inp = node.querySelector("input");
			inp.type = "number";
			inp.min = 0;
			return node;
		},
	},
	webix.editors.text
);
