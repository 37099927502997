import { JetView } from "webix-jet";
import TreeView from "./tree";
import ScalesView from "../chart/scale";
import DiagramView from "./diagram";

import { createState } from "jet-restate";

export default class RDiagramView extends JetView {
	config() {
		const _ = this.app.getService("locale")._;
		const compact = this.getParam("compact", true);

		const contrast = webix.skin.$name === "contrast";
		const unit = this.app.getService("local").getScales().minUnit;
		return {
			type: contrast ? "clean" : "line",
			rows: [
				{
					view: "toolbar",
					borderless: contrast,
					elements: [
						{},
						{
							view: "radio",
							width: 330,
							value: "hours",
							localId: "radio",
							options: [
								{ id: "hours", value: `${_("Hours per")} ${_(unit)}` },
								{ id: "tasks", value: `${_("Tasks per")} ${_(unit)}` },
							],
							on: {
								onChange: v => {
									this.app.callEvent("rdiagram:unit:change", [v]);
								},
							},
						},
					],
				},
				{
					type: "clean",
					cols: [
						TreeView,
						{
							view: compact ? "spacer" : "resizer",
							css: "webix_gantt_resizer",
							width: compact ? 1 : 4,
						},
						{
							localId: "diagram",
							rows: [ScalesView, DiagramView],
						},
					],
				},
			],
		};
	}

	init() {
		const localState = createState({
			top: 0,
		});
		this.setParam("localState", localState);

		this.on(this.app, "rdiagram:tree:toggle", v => {
			if (v) this.$$("diagram").show();
			else this.$$("diagram").hide();
		});

		const _ = this.app.getService("locale")._;
		const radio = this.$$("radio");
		this.on(this.app, "onScalesUpdate", (scales, old) => {
			if (scales.minUnit !== old.minUnit) {
				radio.config.options.map(o => {
					const words = o.value.split(" ");
					words[words.length - 1] = _(scales.minUnit);
					o.value = words.join(" ");
				});
				radio.refresh();
			}
		});
	}
}
