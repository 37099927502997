import { JetView } from "webix-jet";

import ScaleView from "./scale";
import BarsView from "./bars";

export default class ChartView extends JetView {
	config() {
		return { rows: [ScaleView, BarsView] };
	}
	init() {
		this.on(this.app, "bars:toggle", v => {
			if (v) this.getRoot().show();
			else this.getRoot().hide();
		});
	}
}
